<template>
  <div>
    <DataTable
      class="dt-customers"
      title="All Customers"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      :actionFunc="canCreateCustomers ? showCreateCustomerModal : undefined"
      actionIcon="plus"
      itemType="Customer"
      :initialSort="1"
    />
    <Modal ref="createCustomerModal" />
  </div>
</template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'
import Modal from '@/components/fleet/ModalAddUpdateCustomer.vue'

import Reloadables from '@/services/reloadables'
import FleetDataService from '@/services/fleet/FleetDataService'
import auth from '@/app/auth'

export default {
  name: 'customers-list',
  extends: BaseFleetComponent,
  data () {
    return {
      listFilter: 'recent',
      cols: [
        { text: 'Customer ID', sort: 0, style: 'width:10px !important;' },
        { text: 'Display Name', sort: 1, style: 'width:10px !important;' },
        { text: 'Classif.', sort: 2 },
        { icon: 'star', sort: 3, style: 'text-align:center', title: 'Is High Priority?' },
        { icon: 'bug', sort: 4, style: 'text-align:center', title: 'Pest & Disease Forecasts' },
        { text: 'Notes', sort: 5 }
      ],
      loading: false
    }
  },
  computed: {
    canCreateCustomers () {
      return auth.userHasPrivilege('customers', 'create', '*' )
    },
    rows () {
      const out = []
      if (this.activeCustomers && this.activeCustomers.length > 0) {
        for (const customer of this.activeCustomers) {
          const key = ('customer.' + customer.id).toLowerCase()
          if (!(key in FleetDataService.manifest.data)) {
            continue
          }
          out.push([
            { value: this.getLastWordIfUUID(customer.id), route: 'customer-details', params: { id: customer.id }, class: this.customerLinkColor(customer, '') },
            { value: customer.displayName, class: customer.highPriority ? 'gold' : undefined },
            { value: customer.classification, class: 'capitalize' },
            { leftIcon: customer.highPriority ? 'star' : 'minus', class: customer.highPriority ? 'gold' : undefined, sortValue: customer.highPriority ? 0 : 1 },
            { leftIcon: this.pdIcon(customer.pestDiseaseMonitoring), style: 'text-align:center', sortValue: this.pdVal(customer.pestDiseaseMonitoring) },
            { value: customer.notes }
          ])
        }
      }
      return out
    }
  },
  methods: {
    pdIcon (input) {
      if (input === 1 || input === 2) {
        return 'check'
      }
      return 'minus'
    },
    pdVal (input) {
      if (input === 1) {
        return 1
      }
      if (input === 2) {
        return 0
      }
      return 2
    },
    showCreateCustomerModal () {
      this.$refs.createCustomerModal.show()
    },
    customerLinkColor (customer, baseClass) {
      let classes = baseClass
      if (customer.status === 'inactive') {
        classes += ' inactive'
      } else if (customer.highPriority) {
        classes += ' gold'
      }
      return classes
    },
    refreshList () {
      this.loading = true
      FleetDataService.listCustomers()
        .then(response => {
          this.customers = response.data
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('customers-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('customers-list')
  },
  components: { Modal, DataTable }
}
</script>
