export const HoldingSites = {
    // use format of ====>    "DisplayName": "id",
    //
    "Monterey - Triage": "9e68de73-8bbc-4e48-a52f-cb43d146c0bc",
    "Roseville - Triage": "40c34ec6-39f8-4859-8ac2-2c77d3d2f3b9",
    "Santa Rosa - Triage": "0e9550b0-9694-4fda-b704-cad74152e9e7",
    "Virginia - Triage": "48232042-2f02-4373-a9f5-0ee694d2512d",
    "": "",
    "Canada - Field Read": "c1e672d2-0e98-4a13-bd5c-b762063c88ac",
    "Monterey - Field Ready": "94e29403-ea04-4a43-9042-50e15606e0ab",
    "Roseville - Field Ready": "ff94d868-ec30-4210-8c18-d20a42139018",
    "Santa Rosa - Field Ready": "4ea1a56a-b4c6-4275-981e-af17dd7126a1",
    "Texas - Field Ready": "75165a7b-cf58-4dd8-9c71-db7b1c8a9ba8",
    "Virginia - Field Ready": "3b9f7e9e-33d1-4457-8ef1-1efdffbbca1e",
    " ": "",
    "Orphaned Devices": "orphaned-devices",
    "Retired Devices": "retired-devices",
    "Device Triage Holding Area": "device-triage",
  }
  