<template>
  <div>
    <ModalAddUpdateStaffRole ref="createRoleModal" />
    <ModalAddUpdateStaffRole ref="updateRoleModal" v-if="selectedRole" :role="selectedRole" :successFunc="getRoles" />
    <ModalAddStaffRolePriv ref="addRolePrivModal" v-if="selectedRole" :role="selectedRole" :successFunc="loadPrivs" />

    <h4>
      <fa icon="building-lock" class="map-icon me-2" />
      Manage Staff Roles
    </h4>

    <hr class="mt-3 mb-3" />

    <div class="d-flex">
      <SelectRow
        label="Select Role"
        v-model="roleID"
        :change="roleSelected"
      >
        <template v-slot:options>
          <option value="">-- Select Role --</option>
          <option v-for="role in roles" :value="role.id" :key="role.id">
            {{ role.label }}
          </option>
        </template>
      </SelectRow>
      <label class="mx-3">
        or
      </label>
      <button class="btn btn-green" style="height:32px" @click="showCreateRole">
        Create New Role
      </button>
    </div>

    <hr class="mt-1 mb-3" />

    <h4 class="mb-2 ms-md-3" v-if="selectedRole">{{ selectedRole.label }}</h4>

    <div class="d-flex ms-md-3">
      <InfoPane
        title="Role Details"
        class="dt-info-pane-sm ms-0 me-3 mt-3 mb-auto"
        :actionFunc="actionFunc"
        actionIcon="pen-to-square"
        itemType="Role Details"
        v-if="selectedRole"
      >
        <template v-slot:info>
          <div>

            <div class="py-2 px-2 add-border-bottom" v-if="selectedRole.locked">
              <fa icon="lock" class="icon-regular status-caution me-2" />
              <label class="subtle me-2">This role is locked and cannot be edited.</label>
            </div>

            <div class="py-2 px-2 add-border-bottom" v-if="selectedRole.sensitive">
              <fa icon="warning" class="icon-regular status-caution me-2" />
              <span class="status-caution">Privacy-Sensitive Role</span>
            </div>

            <div class="py-2 px-2 add-border-bottom">
              <label class="bold subtle me-2">Display Name:</label>
              <span>{{ selectedRole.label }}</span>
            </div>

            <div class="py-2 px-2 wrap">
              <label class="bold subtle me-2">Description:</label>
              <span>{{ selectedRole.description }}</span>
            </div>

            <div class="p-2 add-border-top" v-if="isAdmin || isImpersonating">
              <label class="bold subtle me-3">Administrator Access:</label>
              <BasicButton v-if="!isImpersonating" caption="Impersonate This Role" :action="() => { startImpersonating(selectedRole) }"/>
              <BasicButton v-if="isImpersonating" caption="Stop Impersonating" :action="() => { stopImpersonating() }"/>
            </div>

            <LastModifiedRow :ts="selectedRole.lastModified" :author="selectedRole.lastModifiedBy" v-if="selectedRole.lastModified" />

          </div>
        </template>
      </InfoPane>

      <DataTable
        class="mt-3"
        title="Role Privileges"
        :rows="privRows"
        :cols="privCols"
        noDataMessage="No Privileges associated with this Role."
        :initialSort="0"
        :actionFunc="privAddActionFunc"
        itemType="Role Privilege"
        :loading="isLoading"
        v-if="selectedRole"
      />
    </div>
  </div>
</template>
<script>
import SelectRow from '@/components/forms/SelectRow.vue'
import BasicButton from '@/components/controls/BasicButton.vue'
import InfoPane from '@/components/tables/InfoPane.vue'
import DataTable from '@/components/tables/DataTable.vue'
import LastModifiedRow from '@/components/forms/LastModifiedRow.vue'
import ModalAddUpdateStaffRole from '@/components/admin/ModalAddUpdateStaffRole.vue'
import ModalAddStaffRolePriv from '@/components/admin/ModalAddStaffRolePriv.vue'

import AdminDataService from '@/services/admin/AdminDataService'
import FleetDataService from '@/services/fleet/FleetDataService'
import HelpContent from '@/services/HelpContent'
import auth from '@/app/auth'

export default {
  components: { SelectRow, InfoPane, LastModifiedRow, DataTable, ModalAddUpdateStaffRole, ModalAddStaffRolePriv, BasicButton },
  data () {
    return {
      roleID: this.$route.params.roleID,
      roles: [],
      isLoading: false,
      privileges: []
    }
  },
  mounted () {
    this.getRoles()
  },
  watch: {
    selectedRole: {
      handler () {
        this.loadPrivs()
      }
    }
  },
  computed: {
    isAdmin () {
      return auth.isAdmin()
    },
    isImpersonating () {
      return this.$state.impersonateRole ? true : false
    },
    privCols () {
      if (this.selectedRole && this.selectedRole.locked) {
        return [
          { text: 'Action', sort: 0 },
          { text: 'Subject', sort: 1 },
          { text: 'Customer', sort: 2 }
        ]
      }
      return [
        { text: 'Action', sort: 0 },
        { text: 'Subject', sort: 1 },
        { text: 'Customer', sort: 2 },
        { text: '', style: 'width:40px !important;' }
      ]
    },
    privRows () {
      const out = []
      if (this.privileges) {
        for (const priv of this.privileges) {
          if (this.selectedRole && this.selectedRole.locked) {
            out.push([
              { value: this.formatVerb(priv.verb), class: { 'status-warning': priv.verb === '*' } },
              { value: this.formatSubject(priv.subject), class: { 'status-warning': priv.subject === '*' } },
              { value: this.formatCustomer(priv.customer), class: { 'status-warning': priv.customer === '*' } }
            ])
          } else {
            out.push([
              { value: this.formatVerb(priv.verb), class: { 'status-warning': priv.verb === '*' } },
              { value: this.formatSubject(priv.subject), class: { 'status-warning': priv.subject === '*' } },
              { value: this.formatCustomer(priv.customer), class: { 'status-warning': priv.customer === '*' } },
              { icon: 'trash-can', click: this.deletePrivilege, i: priv, class: 'dt-centered' }
            ])
          }
        }
      }
      return out
    },
    actionFunc () {
      if (!this.selectedRole || this.selectedRole.locked) {
        return null
      }
      return this.showEditRole
    },
    privAddActionFunc () {
      if (!this.selectedRole || this.selectedRole.locked) {
        return null
      }
      return this.showAddPriv
    },
    selectedRole () {
      if (!this.roleID) {
        return null
      }
      for (const r of this.roles) {
        if (r.id === this.roleID) {
          return r
        }
      }
      return null
    }
  },
  methods: {
    startImpersonating (role) {
      role = { ...role }
      auth.startImpersonating(role, [ ...this.privileges ])
      this.$state.impersonateRole = role
    },
    stopImpersonating () {
      auth.stopImpersonating()
      delete this.$state.impersonateRole
    },
    loadPrivs () {
      if (!this.roleID || !this.selectedRole) {
        this.privileges = []
        return
      }
      this.isLoading = true
      AdminDataService.getStaffRolePrivs(this.roleID)
        .then(response => {
          this.privileges = response.data
        })
        .catch(e => {
          let message = 'Failed to load Role Privileges'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    formatCustomer (input) {
      if (input === '*') {
        return 'ANY'
      }
      return FleetDataService.getCustomerDisplayName(input)
    },
    formatSubject (input) {
      if (input === '*') {
        return 'ANY'
      }
      return input
    },
    formatVerb (input) {
      if (input === '*') {
        return 'ANY'
      }
      return input
    },
    deletePrivilege (priv) {
      if (priv && priv.roleID && priv.privilegeID) {
        this.isLoading = true
        AdminDataService.deleteStaffRolePriv(priv.roleID, priv.privilegeID)
          .then(() => {
            HelpContent.setTimedFlashMessage('Privilege Removed from Role')
            this.loadPrivs()
          })
          .catch(e => {
            let message = 'Failed to Remove Privilege from Role'
            if (e.response && e.response.data && e.response.data.message) {
              message += `: ${e.response.data.message}`
            }
            HelpContent.setFlashMessage(message, true)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    roleSelected () {
      if (this.roleID) {
        this.$router.push({ name: 'admin-staff-roles-id', params: { roleID: this.roleID } })
      } else if (this.$route.params.roleID) {
        this.$router.push({ name: 'admin-staff-roles' })
      }
    },
    showAddPriv () {
      this.$refs.addRolePrivModal.show()
    },
    showEditRole () {
      this.$refs.updateRoleModal.show()
    },
    showCreateRole () {
      this.$refs.createRoleModal.show()
    },
    getRoles () {
      this.isLoading = true
      AdminDataService.getStaffRoles()
        .then(response => {
          var data = response.data
          data.sort((a, b) => {
            if (a.label < b.label) {
              return -1
            } else if (a.label > b.label) {
              return 1
            }
            return 0
          })
          this.roles = data
        })
        .catch(e => {
          let message = 'Failed to load Staff Roles list'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
