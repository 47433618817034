<template>
  <div class="d-flex input-row text-input-row align-items-center">
    <label :class="labelClasses">
      {{ label }}
      <slot name="labelIcon" />
    </label>
    <div>
      <div class="d-flex form-right-input align-items-center">
        <input
          type="text"
          :class="inputClassesMonth"
          :maxlength="2"
          :placeholder="showPlaceholderBelow ? undefined : 'MM'"
          style="width: 39px; margin-right: 2.5px;"
          @keypress="checkInput"
          :disabled="disabled"
          v-model="month"
        />
        <input
          type="text"
          :class="inputClassesDay"
          :maxlength="2"
          :placeholder="showPlaceholderBelow ? undefined : 'DD'"
          style="width: 36px; margin-right: 2.5px;"
          @keypress="checkInput"
          :disabled="disabled"
          v-model="day"
        />
        <input
          type="text"
          :class="inputClassesYear"
          :maxlength="2"
          :placeholder="showPlaceholderBelow ? undefined : 'YY'"
          style="width: 34px; margin-right: 9.5px;"
          @keypress="checkInput"
          :disabled="disabled"
          v-model="year"
        />
        <input
          v-if="!hideTime"
          type="text"
          :class="inputClassesHour"
          :maxlength="2"
          :placeholder="showPlaceholderBelow ? undefined : 'HH'"
          style="width: 36px; margin-right: 1.5px;"
          @keypress="checkInput"
          :disabled="disabled"
          v-model="hour"
        />
        <span class="subtle" v-if="!hideTime">:</span>
        <input
          v-if="!hideTime" 
          type="text"
          :class="inputClassesMinute"
          :maxlength="2"
          :placeholder="showPlaceholderBelow ? undefined : 'mm'"
          style="width: 39px; margin-left: 1.5px;"
          @keypress="checkInput"
          :disabled="disabled"
          v-model="minute"
        />
      </div>
    </div>
  </div>
  <div class="subtle" style="display: flex; margin-top: -5px;" v-if="showPlaceholderBelow">
    <label :class="labelClasses.replaceAll(' label-required', '')">&nbsp;</label>
    <div style="margin-left: 15px">MM</div>
    <div style="margin-left: 15px">DD</div>
    <div style="margin-left: 15px">YY</div>
  </div>
  <div v-if="error" class="form-row-error">
    {{ error }}
  </div>
</template>
<script>
export default {
  props: ['label', 'modelValue', 'labelClass', 'inputClass', 'required',
    'disabled', 'valueChangedFunc', 'hideTime', 'showPlaceholderBelow'],
  emits: ['update:modelValue'],
  data () {
    return {
      year: this.defaultYear(),
      month: this.defaultMonth(),
      day: this.defaultDay(),
      hour: this.defaultHour(),
      minute: this.defaultMinute()
    }
  },
  methods: {
    defaultYear () {
      if (this.modelValue) {
        const date = new Date(this.modelValue * 1000)
        return (date.getUTCFullYear() % 2000).toString()
      }
      return undefined
    },
    defaultMonth () {
      if (this.modelValue) {
        const date = new Date(this.modelValue * 1000)
        return (date.getUTCMonth() + 1).toString()
      }
      return undefined
    },
    defaultDay () {
      if (this.modelValue) {
        const date = new Date(this.modelValue * 1000)
        return date.getUTCDate().toString()
      }
      return undefined
    },
    defaultHour () {
      if (this.modelValue) {
        const date = new Date(this.modelValue * 1000)
        return date.getUTCHours().toString()
      }
      return undefined
    },
    defaultMinute () {
      if (this.modelValue) {
        const date = new Date(this.modelValue * 1000)
        return date.getUTCMinutes().toString()
      }
      return undefined
    },
    checkInput (e) {
      if (!e.key) {
        return
      }
      if (e.metaKey || e.ctrlKey) {
        return
      }
      if (!this.dataTypeCharacters.includes(e.key)) {
        e.preventDefault()
      }
    }
  },
  watch: {
    epochTime: {
      handler () {
        this.$emit('update:modelValue', this.epochTime)
        if (this.valueChangedFunc) {
         this.valueChangedFunc({})
        }
      }
    }
  },
  computed: {
    numericYear () {
      if (this.year == undefined) {
        return undefined
      }
      let sVal = this.year
      if (sVal.length > 1 && sVal.startsWith('0')) {
        sVal = sVal.substring(1)
      }
      const year = parseInt(sVal)
      if (isNaN(year)) {
        return undefined
      }
      return year
    },
    numericMonth () {
      if (this.month == undefined) {
        return undefined
      }
      let sVal = this.month
      if (sVal.length > 1 && sVal.startsWith('0')) {
        sVal = sVal.substring(1)
      }
      const month = parseInt(sVal)
      if (isNaN(month)) {
        return undefined
      }
      return month
    },
    numericDay () {
      if (this.day == undefined) {
        return undefined
      }
      let sVal = this.day
      if (sVal.length > 1 && sVal.startsWith('0')) {
        sVal = sVal.substring(1)
      }
      const day = parseInt(sVal)
      if (isNaN(day)) {
        return undefined
      }
      return day
    },
    numericHour () {
      if (this.hour == undefined) {
        return undefined
      }
      let sVal = this.hour
      if (sVal.length > 1 && sVal.startsWith('0')) {
        sVal = sVal.substring(1)
      }
      const hour = parseInt(sVal)
      if (isNaN(hour)) {
        return undefined
      }
      return hour
    },
    numericMinute () {
      if (this.minute == undefined) {
        return undefined
      }
      let sVal = this.minute
      if (sVal.length > 1 && sVal.startsWith('0')) {
        sVal = sVal.substring(1)
      }
      const minute = parseInt(sVal)
      if (isNaN(minute)) {
        return undefined
      }
      return minute
    },
    yearError () {
      const minYear = 21
      const maxYear = (new Date()).getUTCFullYear() - 2000
      if (this.numericYear == undefined) {
        return undefined
      }
      if (this.numericYear < minYear || this.numericYear > maxYear ) {
        return `Year must be between ${minYear} and ${maxYear}`
      }
      return undefined
    },
    monthError () {
      if (this.numericMonth == undefined) {
        return undefined
      }
      if (this.numericMonth < 1 || this.numericMonth > 12 ) {
        return 'Month must be between 1 and 12'
      }
      return undefined
    },
    dayError () {
      if (this.numericDay == undefined) {
        return undefined
      }
      let maxDay = 31
      if (this.numericMonth) {
        if (this.numericMonth === 2) { 
          maxDay = 28
          if (this.numericYear && this.numericYear % 4 === 0 && this.numericYear % 100 !== 0) {
            maxDay = 29
          }
        } else if (![1, 3, 5, 7, 8, 10, 12].includes(this.numericMonth)) {
          maxDay = 30
        }
      }
      if (this.numericDay < 1 || this.numericDay > maxDay ) {
        return `Day must be between 1 and ${maxDay}`
      }
      return undefined
    },
    hourError () {
      if (this.numericHour == undefined) {
        return undefined
      }
      if (this.numericHour < 0 || this.numericHour > 23 ) {
        return 'Hour must be between 0 and 23'
      }
      return undefined
    },
    minuteError () {
      if (this.numericMinute == undefined) {
        return undefined
      }
      if (this.numericMinute < 0 || this.numericMinute > 59 ) {
        return 'Minute must be between 0 and 59'
      }
      return undefined
    },
    error () {
      if (this.yearError) {
        return this.yearError
      }
      if (this.monthError) {
        return this.monthError
      }
      if (this.dayError) {
        return this.dayError
      }
      if (this.hourError) {
        return this.hourError
      }
      if (this.minuteError) {
        return this.minuteError
      }
      return undefined
    },
    epochTime () {
      if (this.numericYear === undefined || this.numericMonth === undefined ||
      this.numericDay === undefined) {
        return undefined
      }
      let numHour = this.hideTime ? 0 : this.numericHour
      let numMinute = this.hideTime ? 0 : this.numericMinute
      if (numHour === undefined || numMinute === undefined) {
        return undefined
      }
      const year = this.numericYear.toString().padStart(2, '0')
      const month = this.numericMonth.toString().padStart(2, '0')
      const day = this.numericDay.toString().padStart(2, '0')
      const hour = this.numericHour.toString().padStart(2, '0')
      const minute = this.numericMinute.toString().padStart(2, '0')
      const timestamp = Date.parse(`20${year}-${month}-${day}T${hour}:${minute}:00.000Z`) / 1000
      return timestamp
    },
    dataTypeCharacters (){
      return '0123456789'
    },
    inputClassesYear (){
      let out = this.inputClasses
      if (this.yearError) {
        out += ' text-input-error'
      }
      return out
    },
    inputClassesMonth (){
      let out = this.inputClasses
      if (this.monthError) {
        out += ' text-input-error'
      }
      return out
    },
    inputClassesDay (){
      let out = this.inputClasses
      if (this.dayError) {
        out += ' text-input-error'
      }
      return out
    },
    inputClassesHour (){
      let out = this.inputClasses
      if (this.hourError) {
        out += ' text-input-error'
      }
      return out
    },
    inputClassesMinute (){
      let out = this.inputClasses
      if (this.minuteError) {
        out += ' text-input-error'
      }
      return out
    },
    inputClasses (){
      let out = 'form-input text-input ph-no-italic'
      if (this.inputClass) {
        if (typeof this.inputClass === 'object') {
          for (const k in this.inputClass) {
            if (this.inputClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.inputClass}`
        }
      }
      return out
    },
    labelClasses (){
      let out = 'input-row-header'
      if (this.required) {
        out += ' label-required'
      }
      if (this.labelClass) {
        if (typeof this.labelClass === 'object') {
          for (const k in this.labelClass) {
            if (this.labelClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.labelClass}`
        }
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      return out
    }
  }
}
</script>
