<template>
  <div :class="classes">
    {{ text }}
  </div>
</template>
<script>
export default {
  props: ['text', 'textClass', 'borderTop', 'borderBottom'],
  computed: {
    classes () {
      let out = 'submission-form-section-text'
      if (this.textClass) {
        out =`${out} ${this.textClass}`
      }
      if (this.borderTop) { 
        out =`${out} add-border-top`
      }
      if (this.borderBottom) { 
        out =`${out} add-border-bottom`
      }
      return out
    }
  }
}
</script>
