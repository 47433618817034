<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close" sz="sm" :canClose="canClose">
      <template v-slot:header>
        Upload File
      </template>
      <template v-slot:body>
      <div class="modal-upload-file-body" v-if="!phase || phase === 'selectFile'">
        <FormFieldRow>
          <template v-slot:input>
            <div class="upload-single-file">
              <div class="single-file-input" v-if="!selectedFileName">
                <div>
                  <label class="btn" title="Select a File" for="libraryFile">Select a File to Upload</label>
                </div>
                <div class="upload-general-notice subtle">
                  Your file will be associated with<br />{{ locationName }}
                </div>
              </div>
              <div class="single-file-selection" v-else>
                <label class="single-file-selection-name">{{ displayFileName }}</label>
                <label class="btn btn-short px-2" title="Select a File" for="libraryFile">Change</label>
              </div>
              <input class="d-none" type="file" id="libraryFile" @change="filesChanged($event)" accept=".heic,.jpg,.jpeg,.png,.csv,.tsv,.pdf,.mp4" />
            </div>
          </template>
        </FormFieldRow>
        <template v-if="selectedFileName">
        <hr />
        <TextInputRow label="Filename" labelClass="width-110" inputClass="width-210" :suffix="'.' + destFileType" :required="true" v-model="formData.filename" />
        <div class="filename-is-long-warning" v-if="isFileNameLong">If possible, shorten the Filename by {{ filenameOverage }} character{{ filenameOverage > 1 ? "s" : "" }}.</div>
        <div class="filename-is-long-warning subtle" v-if="fileType === 'heic'">HEIC files are automatically converted to JPG.</div>
        <TextInputRow label="Caption" labelClass="width-110" inputClass="width-245" v-model="formData.description" />
        <TextAreaRow label="Notes" labelClass="width-110" inputClass="width-245" v-model="formData.notes" />
        <hr />
        <TextLabelRow label="Customer" valueClass="subtle" :value="customer && customer.displayName ? customer.displayName : None" />
        <TextLabelRow label="Site" valueClass="subtle" :value="site && site.displayName ? site.displayName : None" />
        <SelectRow v-if="!formData.gatewayID" label="Node" labelClass="width-110" selectClass="width-245" :options="nodeOptions" v-model="formData.nodeID" noSelectionLabel="None" />
        <SelectRow v-if="!formData.gatewayID" label="Device" labelClass="width-110" selectClass="width-245" :options="deviceOptions" displayKey="id" v-model="formData.deviceID" noSelectionLabel="None" />
        <SelectRow v-if="!formData.nodeID && !formData.deviceID" label="Gateway" labelClass="width-110" selectClass="width-245" :options="gateways" displayKey="id" v-model="formData.gatewayID" noSelectionLabel="None" />
        <hr />
        <CheckboxInputRow labelClass="width-35" checkboxLabel="Expose this file externally to the Grower" v-model="formData.visibleToCustomer" id="visibleToCustomerCheckbox" :value="1" />
        </template>
      </div>
      <div class="general-status-pane" v-if="phase === 'startingUpload'">
        Preparing to Upload ...
        <div style="margin-top: 60px;">
          <fa icon="hourglass-start" style="max-width: 60px" class="subtle" />
        </div>
      </div>
      <div class="general-status-pane" v-if="phase === 'uploadingFile'">
        Uploading File ...
        <div style="margin-top: 60px;">
          <fa icon="hourglass-half" style="max-width: 60px" class="subtle" />
        </div>
      </div>
      <div class="general-status-pane" v-if="phase === 'finishingUpload'">
        Processing ...
        <div style="margin-top: 60px;">
          <fa icon="hourglass-end" style="max-width: 60px" class="subtle" />
        </div>
      </div>
      <div class="general-status-pane" v-if="phase === 'uploadSuccess'">
        Upload Complete.
        <div style="margin-top: 40px;">
          <fa icon="check" style="max-width: 100px" class="status-ok" />
        </div>
      </div>
      <div class="general-status-pane"  style="margin-left: 20px; margin-right: 20px;" v-if="phase === 'uploadError'">
        <span class="status-error font-enlarge-1">Upload Failed</span>
        <br />
        <div style="font-weight: 400; font-size: .9em; padding: 26px 8px; line-height: 1.75em">
        Unfortunately your file didn't complete<br />
        the upload process. You can press<br />
        Retry below to give it another go.
        </div>
        <hr v-if="failureText" />
        <div class="font-reduce-2" style="text-align: left; font-weight: 400; font-size: .9em; padding: 20px 0px 0px 8px;" v-if="failureText">
          {{ failureText }}
        </div>
      </div>
      </template>
      <template v-slot:footer>
        <button class="btn ms-2" @click="submitNewFile" v-if="selectedFileName" :disabled="!canSubmit">
          {{ submitButtonCaption }}
        </button>
        <button class="btn btn-blue ms-2" @click="close" :disabled="!canClose">
          Cancel
        </button>
      </template>
    </Modal>
  </transition>
</template>
<script>
import Reloadables from '@/services/reloadables'
import { md5 } from '@/services/hash/hash'
import Modal from '@/components/modals/Modal.vue'
import FormFieldRow from '@/components/forms/FormFieldRow.vue'
import TextLabelRow from '@/components/forms/TextLabelRow.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import CheckboxInputRow from '@/components/forms/CheckboxInputRow.vue'

import FileManagementService from '@/services/files/FileManagementService'

export default {
  name: 'file-upload-modal',
  props: ['scope', 'target', 'site', 'nodes', 'customer', 'devices', 'gateways'],
  components: { Modal, FormFieldRow, TextLabelRow, TextInputRow, TextAreaRow, SelectRow, CheckboxInputRow },
  data () {
    return {
      selectedFileName: '',
      isLoading: false,
      isBlocking: false,
      formData: {},
      fileData: undefined,
      phase: 'selectFile',
      failureText: ''
    }
  },
  computed: {
    canClose () {
      return this.phase === 'selectFile' || this.phase === 'uploadError'
    },
    submitButtonCaption () {
      if (this.phase === 'uploadError') {
        return 'Retry'
      }
      return 'Submit'
    },
    filenameOverage () {
      if (!this.isFileNameLong) {
        return 0
      }
      return this.destFilename.length - 20
    },
    isFileNameLong () {
      return this.destFilename && this.destFilename.length > 20
    },
    destFilename () {
      if (!this.formData || !this.formData.filename) {
        return undefined
      }
      return this.formData.filename
    },
    isScopedValid () {
      if (!this.scope) {
        return false
      }
      return ['customer', 'site', 'node', 'device', 'gateway'].includes(this.scope)
    },
    s3FileKey () {
      if (!this.formData || !this.formData.checksum || !this.fileType || 
        !this.isScopedValid) {
        return undefined
      }
      if (['site', 'node', 'device', 'gateway'].includes(this.scope)) {
        if (!this.site) {
          return undefined
        }
        return `/uploads/site/${this.site.id}/${this.formData.checksum}.${this.fileType}`
      }
      return `/uploads/${this.formData.checksum}.${this.fileType}`
    },
    destFileType () {
      if (!this.fileType) {
        return ''
      }
      return this.fileType.replaceAll('heic', 'jpg')
    },
    fileType () {
      if (!this.selectedFileName) {
        return ''
      }
      const i = this.selectedFileName.lastIndexOf('.')
      if (i > 0 && i < this.selectedFileName.length -1) {
        return this.selectedFileName.substring(i+1).toLowerCase().replaceAll('jpeg', 'jpg')
      }
      return ''
    },
    canSubmit () {
      return this.formData && this.fileData && !this.isLoading && this.destFilename && ['uploadError', 'selectFile'].includes(this.phase)
    },
    locationName () {
      if (this.site && this.customer) {
        return `${this.customer.displayName} / ${this.site.displayName}`
      }
      if (this.customer) {
        return this.customer.displayName
      }
      return 'Unknown Location'
    },
    displayFileName () {
      if (this.selectedFileName) {
        return this.selectedFileName
      }
      return 'No File Selected'
    },
    deviceOptions () {
      var out = []
      if (!this.devices) {
        return out
      }
      if (!this.formData.nodeID) {
        return this.devices
      }
      for (const device of this.devices) {
        if (device.node !== this.formData.nodeID) {
          continue
        }
        out.push(device)
      }
      return out
    },
    nodeOptions () {
      var out = []
      if (!this.nodes || !this.devices || this.formData.gatewayID) {
        return out
      }
      if (!this.formData.deviceID || !this.devices) {
        return this.nodes
      }
      let device = undefined
      for (device of this.devices) {
        if (device && device.id === this.formData.deviceID) {
          break
        }
      }
      if (!device) {
        return this.nodes
      }
      for (const node of this.nodes) {
        if (device.node !== node.id) {
          continue
        }
        out.push(node)
      }
      return out
    }
  },
  methods: {
    timedClose () {
      Reloadables.requestReload()
      setTimeout(this.close, 1500)
    },
    trimExtension(filename) {
      if (!filename) {
        return filename
      }
      const i = filename.lastIndexOf('.')
      if (i > 0 && i < filename.length -1) {
        return filename.substring(0, i)
      }
      return ''
    },
    submitNewFile () {
      if (!this.customer || !this.formData.filename || !this.s3FileKey) {
        return
      }
      this.phase = 'startingUpload'
      const ct = FileManagementService.getContentType(this.s3FileKey)
      this.isLoading = true
      const out = {
        status: 'pending',
        customerID: this.customer.id,
        fileType: this.fileType,
        s3url: this.s3FileKey
      }
      if (this.site) {
        out.siteID = this.site.id
      }
      let canDoGateway = true
      if (this.formData.nodeID) {
        out.nodeID = this.formData.nodeID
        canDoGateway = false
      }
      if (this.formData.deviceID) {
        out.deviceID = this.formData.deviceID
        canDoGateway = false
      }
      if (canDoGateway && this.formData.gatewayID) {
        out.gatewayID = this.formData.gatewayID
      }
      if (this.formData.description) {
        out.description = this.formData.description
      }
      if (this.formData.notes) {
        out.notes = this.formData.notes
      }
      if (this.destFilename) {
        out.filename = `${this.destFilename}${this.fileType ? '.' : ''}${this.fileType}`
      }
      if (this.formData.visibleToCustomer) {
        out.visibleToCustomer = 1
      }
      FileManagementService.startNewFileWorkflow(this.customer.id, out)
      .then(resp => {
        if (resp && resp.data && resp.data.uploadUrl && resp.data.id) {
          console.log('uploading file', this.s3FileKey, resp.data.uploadUrl)
          this.phase = 'uploadingFile'
          FileManagementService.uploadFile(resp.data.uploadUrl, this.fileData, ct)
          .then(() => {
            // file uploaded, update workflow status
            this.phase = 'finishingUpload'
            FileManagementService.updateCustomerFileWorkflow(this.customer.id,
              resp.data.id, {status: "success"})
            .then(() => {
              this.phase = 'uploadSuccess'
              this.timedClose()
            })
            .catch(e => {
              this.phase = 'uploadError'
              this.failureText = e
            })
            .finally(() => {
              this.isLoading = false
            })
          })
          .catch(e => {
            console.log('failed to upload to s3', e)
            // file uploaded FAILED, update workflow status
            FileManagementService.updateCustomerFileWorkflow(this.customer.id,
              resp.data.id, {status: "failed"}
            )
            this.phase = 'uploadError'
            this.failureText = e
            this.isLoading = false
          })
        }
      })
      .catch(e => {
        console.log('ERROR with startNewFileWorkflow', e)
        this.phase = 'uploadError'
        this.failureText = e
        this.isLoading = false
      })
    },
    close () {
      this.$refs.baseModal.close()
    },
    reset () {
      this.formData = {}
      this.selectedFileName = ''
      this.isBlocking = false
      this.isLoading = false
      this.phase = 'selectFile'
      this.fileData = undefined
      this.failureText = ''
    },
    isPlainText(filename) {
      return filename.toLowerCase().endsWith('.csv') ||
        filename.toLowerCase().endsWith('.tsv') ||
        filename.toLowerCase().endsWith('.json') ||
        filename.toLowerCase().endsWith('.txt')
    },
    filesChanged (e) {
      if (!e.target.files || e.target.files.length === 0) {
        this.selectedFileName = ''
        delete this.formData.filename
        delete this.formData.fileContentBase64
        return
      }
      const files = e.target.files
      for (const file of files) {
        if (file) {
          this.isLoading = true
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            this.selectedFileName = file.name
            this.formData.filename = this.trimExtension(file.name)
            this.fileData = reader.result
          }, false)
          if (this.isPlainText(file.name)) {
            reader.readAsText(file)
          } else {
            reader.readAsArrayBuffer(file)
          }
          const md5reader = new FileReader()
          md5reader.addEventListener('load', () => {
            this.formData.checksum = md5(md5reader.result)
            this.isLoading = false
          }, false)
          if (this.isPlainText(file.name)) {
            md5reader.readAsText(file)
          } else {
            md5reader.readAsBinaryString(file)
          }
        }
        break // only care about the first file
      }
    },
    show () {
      this.reset()
      this.isBlocking = true
    },
    close () {
      this.isBlocking = false
    },
  }
}
</script>
<style scoped>
  .modal-upload-file-body {
    min-height: 448px;
  }

  .general-status-pane {
    min-height: 448px;
    margin-top: 135px;
    text-align: center;
    font-weight: 600;
    font-size: var(--font-enlarge-3);
  }

  .general-status-pane-header {
    font-size: var(--font-enlarge-4);
  }

  div.upload-general-notice {
    margin-top: 60px;
    text-align: center;
  }

  .upload-single-file {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    margin-top: 5px;
  }

  .single-file-input {
    width: 358px;
    margin-top: 125px;
    text-align: center;
  }

  .single-file-selection {
    display: flex;
    height: 25.71px;
    vertical-align: middle;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
  }

  .single-file-selection .single-file-selection-name {
    min-width: 280px;
    text-align: right;
  }

  div.upload-single-file div.single-file-selection button.btn.btn-short,
  div.upload-single-file div.single-file-selection label.btn.btn-short {
    max-height: 25.71px !important;
    height: 25.71px;
    margin-top: 0px;
  }

  .single-file-selection label {
    font-size: var(--font-reduce-2);
    margin-right: 10px;
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
  }
  .filename-is-long-warning {
    color: var(--app-caution-color);
    text-align: right;
    margin-right: 15px;
    margin-top: -2px;
    margin-bottom: 5px;
    font-size: var(--font-reduce-2);
  }

</style>