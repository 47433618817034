<template>
  <div>
    <DataTable
      class="dt-info-pane-sm"
      title="Sites under this Customer"
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      :actionFunc="canCreateSites ? showCreateSiteModal : undefined"
      actionIcon="plus"
      itemType="Site"
      style="max-width:520px;"
      :initialSort="0"
    />
    <Modal ref="createSiteModal" />
  </div>
</template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'
import Reloadables from '@/services/reloadables'
import FleetDataService from '@/services/fleet/FleetDataService'
import Modal from '@/components/fleet/ModalAddUpdateSite.vue'
import HelpContent from '@/services/HelpContent'
import auth from '@/app/auth'

export default {
  name: 'sites-by-customer-list',
  extends: BaseFleetComponent,
  data () {
    return {
      listFilter: 'recent',
      cols: [
        { text: 'Site ID', sort: 0 },
        { icon: 'clock', sort: 1 },
        { icon: 'heart-pulse', sort: 2 },
        { text: 'Name', sort: 3 }
      ],
      sites: null
    }
  },
  computed: {
    canCreateSites () {
      return auth.userHasPrivilege('sites', 'create', this.customer ? this.customer.id : 'XXX' )
    },
    rows () {
      const out = []
      if (this.sites && this.sites.length > 0) {
        for (const site of this.sites) {
          out.push([
            { value: this.getLastWordIfUUID(site.id), route: 'site-details', params: { id: site.id }, class: this.siteLinkColor(site, '') },
            { value: this.getSince(site.report_time), class: this.lastReportClass(site), sortValue: Date.now() - site.report_time },
            { value: this.isProd(site.hwMonitoringStatus), style: 'text-align: center' },
            { value: site.displayName }
          ])
        }
      }
      return out
    }
  },
  methods: {
    showCreateSiteModal () {
      this.$refs.createSiteModal.show()
    },
    isProd (b) {
      if (b) {
        return '✓'
      }
      return '-'
    },
    siteLinkColor (site, baseClass) {
      let classes = baseClass
      if (site.status === 'inactive') {
        classes += ' inactive'
      }
      return classes
    },
    lastReportClass (site) {
      let classes = ''
      if (!site) {
        return classes
      }
      if (!site.monitoringMuted && this.isTimeNotable(site.report_time)) {
        classes += ' notable'
      }
      if (site.hwMonitoringStatus && this.isTimeAlertable(site.report_time)) {
        classes += ' status-error'
      }
      if (site.hwMonitoringStatus && this.isTimeCautionable(site.report_time)) {
        classes += ' status-caution'
      }
      return classes
    },
    retrieveSites (id) {
      this.isLoading = true
      FleetDataService.getSitesByCustomer(id)
        .then(response => {
          this.sites = response.data
        })
        .catch(e => {
          let message = 'Unable to Customer Sites List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    refreshList () {
      this.retrieveSites(this.$route.params.id)
    }
  },
  mounted () {
    Reloadables.registerReloadable('sites-by-customer-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('sites-by-customer-list')
  },
  components: { DataTable, Modal }
}
</script>
