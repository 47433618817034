<template>
  <div class="modal-mask">
    <div :class="wrapperClass">
      <div :class="sizeClass">
        <div class="modal-header align-bottom">
          <slot name="logo" />
          <h4 class="me-auto align-bottom mb-2"><slot name="header" /></h4>
          <Spinner ref="modalSpinner" class="mb-1 me-3" v-if="reloadable" @click="requestReload()"/>
          <HelpIcon v-if="helpCopy" class="me-2" :clickFunc="setIsHelpVisible" />
        </div>

        <div :class="{'scrollable-modal': isScrollable}" v-if="!isHelpVisible">
          <div class="modal-body mt-2 mb-2" :class="bodyClass">
            <slot name="body" />
          </div>
          <div class="modal-footer">
            <div class="modal-spinner-lower-left">
              <Spinner :spinOnCreation="true" v-if="!reloadable && showSpinner" />
            </div>
            <div>
              <slot name="footer">
                <span class="subtle-info" v-if="closeTime">{{ closeTime }}</span>
                <button class="btn btn-blue" @click="$emit('close')" :disabled="canClose === false">
                  Close
                </button>
              </slot>
            </div>
          </div>
        </div>
        <div class="modal-body mx-2" v-else>
          <pre class="help-copy">{{ helpCopy }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from '@/components/Spinner.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import HelpIcon from '../help/HelpIcon.vue'

export default {
  components: { Spinner, HelpIcon },
  props: ['sz', 'reloadable', 'isScrollable', 'bodyClass', 'helpCopy', 'canClose', 'showSpinner'],
  data () {
    return {
      ct: -1,
      closeTime: '',
      userClosed: false,
      timedMessage: '',
      isHelpVisible: false,
      width: window.innerWidth
    }
  },
  computed: {
    isShortWindow () {
      return window.innerHeight < 780
    },
    isTallWindow () {
      return window.innerHeight > 960
    },
    isWideWindow () {
      return window.innerWidth > 1000
    },
    isNarrowWindow () {
      return window.innerWidth < 500
    },
    displayedMessage () {
      if (this.timedMessage) {
        return this.timedMessage
      }
      if (this.message) {
        return this.message
      }
      return ''
    },
    wrapperClass () {
      const base = 'modal-wrapper'
      let out = base
      if (this.isShortWindow) {
        out = `${out} ${base}-short`
      } else if (this.isTallWindow) {
        out = `${out} ${base}-tall`
      }
      if (this.sz === 'fs') {
        out = `${out} ${base}-fs`
      }
      return out
    },
    sizeClass () {
      var size = 'modal-md'
      if (this.sz === 'sm') {
        size = 'modal-sm'
      } else if (this.sz === 'lg') {
        size = 'modal-lg'
      } else if (this.sz === 'smx2') {
        size = 'modal-smx2'
      } else if (this.sz === 'xl') {
        size = 'modal-xl'
      } else if (this.sz === 'fs') {
        size = 'modal-fs'
      } else if (this.sz === 'auto') {
        if (this.width < 760) {
          size = 'modal-sm'
        } else if (this.width < 900) {
          size = 'modal-md'
        } else {
          size = 'modal-lg'
        }
      }
      return `modal-container ${size}`
    }
  },
  methods: {
    setIsHelpVisible (t) {
      this.isHelpVisible = t === true ? true : false
    },
    checkWidth () {
      if (this.userClosed || this.sz !== 'auto') {
        return
      }
      this.width = window.innerWidth
      setTimeout(this.checkWidth, 100)
    },
    checkSpin () {
      if (this.reloadable &&
        this.$refs.modalSpinner && this.$refs.modalSpinner.stopSpinning) {
        if (Reloadables.isReloading) {
          setTimeout(this.checkSpin, 50)
          return
        }
        this.$refs.modalSpinner.stopSpinning()
      }
    },
    requestReload () {
      if (this.reloadable &&
        this.$refs.modalSpinner && this.$refs.modalSpinner.startSpinning) {
        this.$refs.modalSpinner.startSpinning()
        setTimeout(this.checkSpin, 50)
      }
      Reloadables.requestReload()
    },
    startTimedMessage (message, timer = 3000) {
      this.timedMessage = message
    },
    remainingCloseTime () {
      return this.closeTime
    },
    startTimedClosed (secs) {
      this.ct = secs
      this.closeTime = secs.toString()
      if (this.ct < 0) {
        return
      }
      this.timedClose()
    },
    timedClose () {
      if (this.userClosed) {
        return
      }
      if (this.ct >= 1) {
        this.closeTime = this.ct.toString()
        this.ct -= 1
        this.$emit('updateCloseTime')
        setTimeout(this.timedClose, 1000)
        return
      }
      if (this) {
        this.closeTime = ''
        this.$emit('close')
      }
    },
    handleKeyPress (e) {
      if (e.code === 'Escape' && this.canClose !== false) {
        this.$emit('close')
      }
    },
    close (message = '', isError = false, requestReload = false) {
      if (message !== '') {
        HelpContent.setTimedFlashMessage(message)
      }
      if (requestReload) {
        this.requestReload()
      }
      this.userClosed = true
      document.removeEventListener('keydown', this.handleKeyPress)
    }
  },
  mounted () {
    document.addEventListener('keydown', this.handleKeyPress)
    this.checkWidth()
  },
  unmounted () {
    document.removeEventListener('keydown', this.handleKeyPress)
    this.userClosed = true
  }
}
</script>
<style scoped>

/* Modal Form (e.g., Preferences Pane) */

.modal-mask {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 1, 2, 0.6);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: top;
    padding-top: 40px;
}

.modal-wrapper.modal-wrapper-short {
    padding-top: 1px;
}

.modal-wrapper-fs {
    padding-top: 0px;
}

.modal-container {
    max-width: 420px;
    margin: 0px auto;
    padding: 0px;
    background-color: var(--app-bg-color);
    border-radius: var(--form-border-radius);
    border: 1px var(--form-control-border-color) solid;
    box-shadow: 0 0 50px 40px var(--modal-shadow);
    transition: all 0.3s ease;
}

.modal-header h5 {
    margin-top: 0;
    color: var(--app-highlight-color);
    font-weight: bold;
    font-size: var(--font-enlarge-5);
}

.modal-footer {
    border-top: 1px var(--form-control-border-color) solid;
    padding: 8px;
    justify-content: space-between;
}

.modal-header {
    height: 40px;
    display: flex;
    padding-left: 10px;
    border-bottom: 1px var(--form-control-border-color) solid;
}

.modal-body {
    margin: 15px 0;
    padding: 0px;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
}

.modal-sm {
    width: 375px;
    max-width: 375px;
    min-width: 370px;
}

.modal-md {
    width: 550px;
    max-width: 550px;
    min-width: 550px;
}

.modal-smx2 {
    width: 725px;
    max-width: 725px;
    min-width: 725px;
}

.modal-lg {
    width: 875px;
    max-width: 875px;
    min-width: 875px;
}

.modal-xl {
    width: var(--modal-xl-width);
    max-width: var(--modal-xl-width);
    min-width: var(--modal-xl-width);
}

.modal-fs {
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    height: calc(100vh - var(--navbar-height));
    margin-top: var(--navbar-height);
}

.modal-spinner-lower-left {
  margin-top: -2px;
  margin-left: 2px;
}

</style>