<template>
      <span :class="classes">
        <a>
          <fa icon="rotate" class="spinner-icon" v-bind:class="{ 'spinner-icon-spinning': isLoading, 'spinner-icon-error': isError }" />
        </a>
      </span>
</template>

<script>
export default {
  data () {
    return {
      isLoading: this.spinOnCreation,
      isError: false,
      startTime: 0
    }
  },
  props: ['spinOnCreation', 'spinnerClass'],
  computed: {
    classes () {
      const base = 'spinner'
      if (this.spinnerClass) {
        return `${base} ${this.spinnerClass}`
      }
      return base
    }
  },
  methods: {
    stopSpinningASAP () {
      this.isLoading = false
    },
    startSpinning () {
      this.isLoading = true
      this.isError = false
      this.startTime = Date.now()
    },
    stopSpinning () {
      var timeout = 500 - ((Date.now() - this.startTime) % 500)
      if (timeout > 12) {
        timeout -= 12
      }
      setTimeout(this.stopSpinningASAP, timeout)
    },
    setError () {
      this.isError = true
    }
  }
}
</script>
<style scoped>
.spinner-icon {
    height: 24px;
    width: 24px;
    padding: 2px;
    color: var(--app-highlight-color);
    -webkit-animation: spinner-running 1s infinite linear;
    animation: spinner-running 1s infinite linear;
    animation-play-state: paused;
}

.spinner-icon-spinning {
    animation-play-state: running;
}


@-webkit-keyframes spinner-running {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spinner-running {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

</style>