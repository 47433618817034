<template>
  <transition name="modal">
    <Modal ref="baseModal" v-if="isBlocking" @close="close" sz="sm">
      <template v-slot:header>
        Edit File Data
      </template>
      <template v-slot:body>
      <div class="modal-update-file-body" v-if="!phase || phase === 'editingFile'">
        <TextLabelRow label="Author" valueClass="subtle" :value="file.createdBy" v-if="file.createdBy" />
        <TextLabelRow label="Upload Date" valueClass="subtle" :value="getSinceSecs(file.createdAt) + ' ago'" v-if="file.createdAt" />
        <hr />
        <TextInputRow label="Filename" labelClass="width-110" inputClass="width-210" :suffix="'.' + formData.fileType" :required="true" v-model="formData.filename" />
        <div class="filename-is-long-warning" v-if="isFileNameLong">If possible, shorten the Filename by {{ filenameOverage }} character{{ filenameOverage > 1 ? "s" : "" }}.</div>
        <div class="filename-is-long-warning subtle" v-if="formData.fileType === 'heic'">HEIC files are automatically converted to JPG.</div>
        <TextInputRow label="Caption" labelClass="width-110" inputClass="width-245" v-model="formData.description" />
        <TextAreaRow label="Notes" labelClass="width-110" inputClass="width-245" v-model="formData.notes" />
        <hr />
        <TextLabelRow label="Customer" valueClass="subtle" :value="customer && customer.displayName ? customer.displayName : None" />
        <TextLabelRow label="Site" valueClass="subtle" :value="site && site.displayName ? site.displayName : None" />
        <SelectRow v-if="!formData.gatewayID" label="Node" labelClass="width-110" selectClass="width-245" :options="nodeOptions" v-model="formData.nodeID" noSelectionLabel="None" />
        <SelectRow v-if="!formData.gatewayID" label="Device" labelClass="width-110" selectClass="width-245" :options="deviceOptions" displayKey="id" v-model="formData.deviceID" noSelectionLabel="None" />
        <SelectRow v-if="!formData.nodeID && !formData.deviceID" label="Gateway" labelClass="width-110" selectClass="width-245" :options="gateways" displayKey="id" v-model="formData.gatewayID" noSelectionLabel="None" />
        <hr />
        <CheckboxInputRow labelClass="width-35" checkboxLabel="Expose this file externally to the Grower" v-model="formData.visibleToCustomer" id="visibleToCustomerCheckbox" :value="1" />
      </div>
      <div class="general-status-pane"  style="margin-left: 20px; margin-right: 20px;" v-else-if="phase === 'updateError'">
        <span class="status-error font-enlarge-1">Update Failed</span>
        <br />
        <div style="font-weight: 400; font-size: .9em; padding: 26px 8px; line-height: 1.75em">
          Unfortunately your changes didn't take. Please re-open the editor and try again.
        </div>
        <hr v-if="failureText" />
        <div class="font-reduce-2" style="text-align: left; font-weight: 400; font-size: .9em; padding: 20px 0px 0px 8px;" v-if="failureText">
          {{ failureText }}
        </div>
      </div>
      <div class="general-status-pane" v-if="phase === 'updatingFile'">
        Working ...
        <div style="margin-top: 60px;">
          <fa icon="hourglass-half" style="max-width: 60px" class="subtle" />
        </div>
      </div>
      <div class="general-status-pane" v-else-if="phase === 'updateSuccess'">
        File Updated
        <div style="margin-top: 40px;">
          <fa icon="check" style="max-width: 100px" class="status-ok" />
        </div>
      </div>
      <div class="general-status-pane font-reduce-2 mx-4" v-else-if="phase === 'confirmDelete'">
        Confirm you want to delete this file:<br /><br />
        {{ fullFileName }}
        <br /><br />
        <fa icon="exclamation-triangle" style="width: 130px" /><br /><br />
        You can't undo this.<br />

        Press Confirm to continue.
      </div>
      <div class="general-status-pane" v-else-if="phase === 'deleteSuccess'">
        File Deleted
        <div style="margin-top: 40px;">
          <fa icon="check" style="max-width: 100px" class="status-ok" />
        </div>
      </div>
      </template>
      <template v-slot:footer>
        <button class="btn ms-2" @click="updateFileData" v-if="file" :disabled="!canSubmit">
          Update
        </button>
        <button class="btn btn-red ms-2" @click="() => { phase = 'confirmDelete' }" v-if="file && phase === 'editingFile'" 
          :disabled="isLoading">
          Delete
        </button>
        <button class="btn btn-red ms-2" @click="deleteFile" v-if="file && phase === 'confirmDelete'" 
          :disabled="isLoading">
          Confirm Deletion
        </button>
        <button class="btn btn-blue ms-2" @click="startDownload" v-if="file && file.s3url && phase === 'editingFile'">
          Download
        </button>
        <button class="btn btn-blue ms-2" @click="close">
          Cancel
        </button>
      </template>
    </Modal>
  </transition>
</template>
<script>
import BaseComponent from '@/components/BaseComponent.vue'
import Reloadables from '@/services/reloadables'
import Modal from '@/components/modals/Modal.vue'
import FormFieldRow from '@/components/forms/FormFieldRow.vue'
import TextLabelRow from '@/components/forms/TextLabelRow.vue'
import TextInputRow from '@/components/forms/TextInputRow.vue'
import TextAreaRow from '@/components/forms/TextAreaRow.vue'
import SelectRow from '@/components/forms/SelectRow.vue'
import CheckboxInputRow from '@/components/forms/CheckboxInputRow.vue'

import FileManagementService from '@/services/files/FileManagementService'

export default {
  name: 'file-editor-modal',
  extends: BaseComponent,
  props: ['scope', 'target', 'site', 'nodes', 'customer', 'devices', 'gateways', 'file'],
  components: { Modal, FormFieldRow, TextLabelRow, TextInputRow, TextAreaRow, SelectRow, CheckboxInputRow },
  data () {
    return {
      isLoading: false,
      isBlocking: false,
      formData: {},
      phase: 'editingFile',
      failureText: '',
      oldRoute: ''
    }
  },
  computed: {
    fullFileName () {
      if (this.formData && this.formData.filename) {
        return `${this.formData.filename}${this.formData.fileType ? '.' : ''}${this.formData.fileType}`
      }
      return ''
    },
    filenameOverage () {
      if (!this.isFileNameLong) {
        return 0
      }
      return this.formData.filename.length - 20
    },
    isFileNameLong () {
      return this.formData.filename && this.formData.filename.length > 20
    },
    isScopedValid () {
      if (!this.scope) {
        return false
      }
      return ['customer', 'site', 'node', 'device', 'gateway'].includes(this.scope)
    },
    canSubmit () {
      return this.formData && this.formData.filename && !this.isLoading && ['editingFile'].includes(this.phase)
    },
    locationName () {
      if (this.site && this.customer) {
        return `${this.customer.displayName} / ${this.site.displayName}`
      }
      if (this.customer) {
        return this.customer.displayName
      }
      return 'Unknown Location'
    },
    deviceOptions () {
      var out = []
      if (!this.devices) {
        return out
      }
      if (!this.formData.nodeID) {
        return this.devices
      }
      for (const device of this.devices) {
        if (device.node !== this.formData.nodeID) {
          continue
        }
        out.push(device)
      }
      return out
    },
    nodeOptions () {
      var out = []
      if (!this.nodes || !this.devices || this.formData.gatewayID) {
        return out
      }
      if (!this.formData.deviceID || !this.devices) {
        return this.nodes
      }
      let device = undefined
      for (device of this.devices) {
        if (device && device.id === this.formData.deviceID) {
          break
        }
      }
      if (!device) {
        return this.nodes
      }
      for (const node of this.nodes) {
        if (device.node !== node.id) {
          continue
        }
        out.push(node)
      }
      return out
    }
  },
  methods: {
    startDownload () {
      if (!this.file || !this.file.fileType || !this.file.filename || !this.file.s3url) {
        return
      }
      this.downloadFile(this.file.s3url, this.file.filename)
    },
    timedClose () {
      Reloadables.requestReload()
      setTimeout(this.close, 1500)
    },
    trimExtension(filename) {
      if (!filename) {
        return filename
      }
      const i = filename.lastIndexOf('.')
      if (i > 0 && i < filename.length -1) {
        return filename.substring(0, i)
      }
      return ''
    },
    deleteFile () {
      if (!this.customer || !this.formData.filename || !this.formData.id ) {
        return
      }
      this.phase = 'updatingFile'
      this.isLoading = true
      FileManagementService.deleteCustomerFile(this.customer.id, this.formData.id)
      .then(resp => {
        this.phase = 'deleteSuccess'
        this.timedClose()
      })
      .catch(e => {
        this.phase = 'updateError'
        this.failureText = e
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    updateFileData () {
      if (!this.customer || !this.formData.filename ) {
        return
      }
      this.phase = 'updatingFile'
      this.isLoading = true
      const out = {
      }
      let canDoGateway = true
      var unset = []
      if (this.formData.nodeID) {
        out.nodeID = this.formData.nodeID
        canDoGateway = false
      } else {
        unset.push('nodeID')
      }
      if (this.formData.deviceID) {
        out.deviceID = this.formData.deviceID
        canDoGateway = false
      } else {
        unset.push('deviceID')
      }
      if (canDoGateway && this.formData.gatewayID) {
        out.gatewayID = this.formData.gatewayID
      } else {
        unset.push('gatewayID')
      }
      if (this.formData.description) {
        out.description = this.formData.description
      } else {
        unset.push('description')
      }
      if (this.formData.notes) {
        out.notes = this.formData.notes
      } else {
        unset.push('notes')
      }
      if (this.formData.filename) {
        out.filename = this.fullFileName
      }
      if (this.formData.visibleToCustomer) {
        out.visibleToCustomer = 1
      } else {
        unset.push('visibleToCustomer')
      }

      if (unset.length > 0) {
        out.unset = unset
      }

      FileManagementService.updateCustomerFile(this.customer.id, this.formData.id, out)
      .then(resp => {
        this.phase = 'updateSuccess'
        this.timedClose()
      })
      .catch(e => {
        this.phase = 'updateError'
        this.failureText = e
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    sanitize () {
      if (!this.formData || !this.formData.filename) {
        return
      }
      this.formData.filename = this.trimExtension(this.formData.filename)
    },
    reset () {
      if (this.file) {
        this.formData = { ...this.file }
        this.sanitize()
      } else {
        this.formData = {}
      }
      this.isBlocking = false
      this.isLoading = false
      this.phase = 'editingFile'
    },
    show () {
      this.reset()
      this.isBlocking = true
      let path = window.location.hash
      let st = path.indexOf('/file/')
      if (st > 0) {
        path = path.substring(0, st)
      } else if (this.file && this.file.id) {
        history.pushState(
          {},
          null,
          `${path}/file/${this.file.id}/edit`
        )
      }
      this.oldRoute = path
    },
    close () {
      this.isBlocking = false
      this.$refs.baseModal.close()
      if (this.oldRoute) {
        history.pushState(
          {},
          null,
          this.oldRoute
        )
      }
    },
  }
}
</script>
<style scoped>
  .modal-update-file-body {
    min-height: 448px;
  }

  .general-status-pane {
    min-height: 448px;
    margin-top: 135px;
    text-align: center;
    font-weight: 600;
    font-size: var(--font-enlarge-3);
  }

  .general-status-pane-header {
    font-size: var(--font-enlarge-4);
  }

  .filename-is-long-warning {
    color: var(--app-caution-color);
    text-align: right;
    margin-right: 15px;
    margin-top: -2px;
    margin-bottom: 5px;
    font-size: var(--font-reduce-2);
  }

</style>