import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center px-0 mx-0" }
const _hoisted_2 = { class: "col col-narrow px-0 mx-0" }
const _hoisted_3 = { class: "col col-wide px-0 mx-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabBar = _resolveComponent("TabBar")!
  const _component_SiteInfo = _resolveComponent("SiteInfo")!
  const _component_SiteLocation = _resolveComponent("SiteLocation")!
  const _component_GatewaysBySiteList = _resolveComponent("GatewaysBySiteList")!
  const _component_DevicesBySiteList = _resolveComponent("DevicesBySiteList")!
  const _component_NodesBySiteList = _resolveComponent("NodesBySiteList")!
  const _component_AlertsByN = _resolveComponent("AlertsByN")!
  const _component_FileManager = _resolveComponent("FileManager")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TabBar, { activeTabName: "sites" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.site)
          ? (_openBlock(), _createBlock(_component_SiteInfo, {
              key: 0,
              site: _ctx.site,
              customer: _ctx.customer,
              onReloadSite: _ctx.loadSite
            }, null, 8, ["site", "customer", "onReloadSite"]))
          : _createCommentVNode("", true),
        (_ctx.site)
          ? (_openBlock(), _createBlock(_component_SiteLocation, {
              key: 1,
              site: _ctx.site,
              nodes: _ctx.nodes,
              nodeColors: _ctx.nodeColors,
              onReloadSite: _ctx.loadSite,
              gateways: _ctx.gateways,
              showNodeEditorFunc: _ctx.showNodeEditor,
              showImageViewerFunc: _ctx.showImageViewer,
              files: _ctx.files
            }, null, 8, ["site", "nodes", "nodeColors", "onReloadSite", "gateways", "showNodeEditorFunc", "showImageViewerFunc", "files"]))
          : _createCommentVNode("", true)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-auto px-0 mx-0 me-3 d-xl" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.site)
          ? (_openBlock(), _createBlock(_component_GatewaysBySiteList, {
              key: 0,
              siteData: _ctx.site,
              siteGateways: _ctx.gateways
            }, null, 8, ["siteData", "siteGateways"]))
          : _createCommentVNode("", true),
        (_ctx.site)
          ? (_openBlock(), _createBlock(_component_DevicesBySiteList, {
              key: 1,
              siteData: _ctx.site,
              class: "mt-3 pt-1",
              nodes: _ctx.nodes,
              deviceList: _ctx.devices,
              customerData: _ctx.customer
            }, null, 8, ["siteData", "nodes", "deviceList", "customerData"]))
          : _createCommentVNode("", true),
        _createVNode(_component_NodesBySiteList, {
          ref: "nodesBySiteList",
          nodeColors: _ctx.nodeColors,
          site: _ctx.site,
          nodes: _ctx.nodes,
          onReloadSite: _ctx.reloadSite,
          loading: _ctx.loading,
          nodeID: _ctx.nodeID,
          deviceList: _ctx.devices
        }, null, 8, ["nodeColors", "site", "nodes", "onReloadSite", "loading", "nodeID", "deviceList"]),
        (_ctx.site)
          ? (_openBlock(), _createBlock(_component_AlertsByN, {
              key: 2,
              entityType: "Site",
              site: _ctx.site,
              class: "mt-3"
            }, null, 8, ["site"]))
          : _createCommentVNode("", true),
        (_ctx.site)
          ? (_openBlock(), _createBlock(_component_FileManager, {
              key: 3,
              ref: "fileManagerControl",
              scope: "site",
              target: _ctx.site.id,
              site: _ctx.site,
              nodes: _ctx.nodes,
              customer: _ctx.customer,
              devices: _ctx.devices,
              gateways: _ctx.gateways,
              fileId: _ctx.fileId,
              files: _ctx.files,
              isLoading: _ctx.loading
            }, null, 8, ["target", "site", "nodes", "customer", "devices", "gateways", "fileId", "files", "isLoading"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}