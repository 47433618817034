<template>
  <div :class="wrapperClasses">
    <label v-if="label" :class="labelClasses" :for="id">{{ label }}</label>
    <div class="col-auto form-right-input">
      <textarea
        :id="id"
        :value="modelValue"
        :required="required"
        :name="id"
        :style="inputStyle"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :readonly="readonly"
        :disabled="disabled"
        :class="inputClasses"
        @input="update"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: ['label', 'id', 'modelValue', 'labelClass', 'inputStyle', 'inputClass', 'wrapperClass',
    'maxlength', 'placeholder', 'required', 'readonly', 'disabled', 'wrap', 'valueChangedFunc'],
  emits: ['update:modelValue'],
  data () {
    return {
      content: this.value
    }
  },
  methods: {
    update (e) {
      this.$emit('update:modelValue', e.target.value)
      if (this.valueChangedFunc) {
        this.valueChangedFunc(e)
      }
    }
  },
  computed: {
    wrapperClasses () {
      const base = 'row form-group'
      if (this.wrapperClass) {
        return `${base} ${this.wrapperClass}`
      }
      return base
    },
    inputClasses () {
      let out = 'notes-box'
      if (this.inputClass) {
        if (typeof this.inputClass === 'object') {
          for (const k in this.inputClass) {
            if (this.inputClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.inputClass}`
        }
      }
      return out
    },
    labelClasses () {
      let out = 'col-form-label form-left-header'
      if (this.required) {
        out += ' label-required'
      }
      if (this.labelClass) {
        out += ` ${this.labelClass}`
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      return out
    }
  }
}
</script>
