<template>
  <div>
    <Modal ref="addDeviceModal" />
    <DataTable
      class="dt-recent-devices"
      :title="listTitle"
      :cols="cols"
      :rows="rows"
      :loading="isLoading"
      :actionFunc="canAddDevice ? showAddDeviceModal : undefined"
      actionIcon="plus"
      itemType="Device"
      :initialSort="3"
    >
      <template v-slot:filter-pillbox>
        <div class="btn-group dt-pillbox me-2" role="group" aria-label="Device State Selector">
          <input type="radio" class="btn-check" value="problems" name="listFilter"
            id="listFilter-problems" v-model="listFilter" autocomplete="off" @click="refreshList('problems')" />
          <label class="btn btn-outline-primary" for="listFilter-problems" title="Devices w/ Problems">
            <fa icon="exclamation-triangle" class="pillbox-icon" :class="{'pillbox-icon-selected': listFilter === 'problems'}" />
          </label>
          <input type="radio" class="btn-check" value="recent" name="listFilter"
            id="listFilter-recent" v-model="listFilter" autocomplete="off" @click="refreshList('recent')" />
          <label class="btn btn-outline-primary" for="listFilter-recent" title="Recent Devices">
            <fa icon="clock" class="pillbox-icon" :class="{'pillbox-icon-selected': listFilter === 'recent'}" />
          </label>
          <input type="radio" class="btn-check" value="overdue" name="listFilter"
            id="listFilter-overdue" v-model="listFilter" autocomplete="off" @click="refreshList('overdue')" />
          <label class="btn btn-outline-primary" for="listFilter-overdue" title="Overdue Devices">
            <fa icon="circle-question" class="pillbox-icon" :class="{'pillbox-icon-selected': listFilter === 'overdue'}" />
          </label>
          <input type="radio" class="btn-check" value="muted" name="listFilter"
            id="listFilter-muted" v-model="listFilter" autocomplete="off" @click="refreshList('muted')" />
          <label class="btn btn-outline-primary" for="listFilter-muted" title="Devices w/ Monitoring Muted">
            <fa icon="bell-slash" class="pillbox-icon" :class="{'pillbox-icon-selected': listFilter === 'muted'}" />
          </label>
          <input type="radio" class="btn-check" value="tu" name="listFilter"
            id="listFilter-tu" v-model="listFilter" autocomplete="off" @click="refreshList('tu')" />
          <label class="btn btn-outline-primary" for="listFilter-tu" title="Temporarily Uninstalled Devices">
            TU
          </label>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import BaseDeviceComponent from '@/components/fleet/BaseDeviceComponent.vue'
import FleetDataService from '@/services/fleet/FleetDataService'
import DataTable from '@/components/tables/DataTable.vue'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'
import Modal from '@/components/fleet/ModalAddUpdateDevice.vue'
import auth from '@/app/auth'

export default {
  name: 'devices-recent-list',
  extends: BaseDeviceComponent,
  data () {
    return {
      listFilter: 'problems',
      cols: [
        { text: 'Device', class: 'd-none d-sm-table-cell', sort: 0 },
        { text: 'Dev', class: 'd-sm-none', sort: 1 },
        { text: 'Node', class: 'd-sm-none', sort: 2 },
        { icon: 'clock', sort: 3 },
        { text: 'Inst', sort: 4 },
        { text: 'Node', class: 'd-none d-sm-table-cell', sort: 5 },
        { text: 'Site', sort: 6 },
        { text: 'Make/Model', sort: 7 },
        { text: 'Notes', sort: 8 }
      ],
      devices: []
    }
  },
  computed: {
    canAddDevice () {
      return auth.userHasPrivilege('devices', 'create', '*')
    },
    rows () {
      const out = []
      if (this.activeDevices && this.activeDevices.length > 0) {
        for (const device of this.activeDevices) {
          const key = ('device.' + device.id).toLowerCase()
          if (!(key in FleetDataService.manifest.data)) {
            continue
          }
          out.push([
            { value: device.id, route: 'device-details', params: { id: device.id }, class: this.deviceLinkColor(device, 'd-none d-sm-table-cell'), isWarning: device.reportingStatus && device.reportingStatus === 'warning' },
            { value: this.shortModel(device.model), route: 'device-details', params: { id: device.id }, class: this.deviceLinkColor(device, 'd-sm-none'), isWarning: device.reportingStatus && device.reportingStatus === 'warning' },
            { value: this.nodeDisplayName(device.node), route: 'device-details', params: { id: device.id }, class: this.deviceLinkColor(device, 'd-sm-none'), isWarning: device.reportingStatus && device.reportingStatus === 'warning' },
            { value: this.getSince(device.report_time), class: this.lastReportClass(device), sortValue: Date.now() - device.report_time },
            { value: this.installationStatus(device) },
            { value: this.nodeDisplayName(device.node), class: 'd-none d-sm-table-cell' },
            { value: this.siteDisplayName(device.site), class: 'pe-3' },
            { value: `${device.manufacturer} / ${device.model}`, class: 'pe-3' },
            { value: device.notes, highlightedPrefix: this.notesPrefix(device) }
          ])
        }
      }
      return out
    },
    listTitle () {
      let title = ''
      if (this.listFilter === 'overdue') {
        title = 'Overdue Devices'
        if (this.rows && !this.isLoading) {
          title += ' (' + this.rows.length + ')'
        }
      } else if (this.listFilter === 'muted') {
        title = 'Unmonitored Prod Devices'
        if (this.rows && !this.isLoading) {
          title += ' (' + this.rows.length + ')'
        }
      } else if (this.listFilter === 'problems') {
        title = 'Devices Requiring Maintenance'
        if (this.rows && !this.isLoading) {
          title += ' (' + this.rows.length + ')'
        }
      } else if (this.listFilter === 'tu') {
        title = 'Temporarily Uninstalled Devices'
        if (this.rows && !this.isLoading) {
          title += ' (' + this.rows.length + ')'
        }
      } else {
        title = 'Devices with Recent Uplinks'
      }
      return title
    },
    refreshFunc () {
      if (this.listFilter) {
        this.$router.replace({ to: 'fleet-home', query: { filter: this.listFilter } })
      }
      if (this.listFilter === 'overdue') {
        return this.retrieveOverdueDevices
      } else if (this.listFilter === 'muted') {
        return this.retrieveMutedDevices
      } else if (this.listFilter === 'problems') {
        return this.retrieveProblematicDevices
      } else if (this.listFilter === 'tu') {
        return this.retrieveTempUninstalledDevices
      }
      return this.retrieveRecentDevices
    }
  },
  methods: {
    nodeDisplayName (input) {
      return FleetDataService.getNodeDisplayName(input)
    },
    siteDisplayName (input) {
      return FleetDataService.getSiteDisplayName(input)
    },
    showAddDeviceModal () {
      this.$refs.addDeviceModal.show()
    },
    deviceLinkColor (device, baseClass) {
      let classes = baseClass
      if (device.status === 'inactive') {
        classes += ' inactive'
      }
      return classes
    },
    notesPrefix (device) {
      if (!device) {
        return ''
      }
      if (device.monitoringMuted) {
        return 'Monitoring disabled.'
      }
      return ''
    },
    lastReportClass (device) {
      let classes = ''
      if (!device) {
        return classes
      }
      if (!device.monitoringMuted && this.isTimeNotable(device.report_time)) {
        classes += ' notable'
      }
      if (!device.monitoringMuted && this.isTimeAlertable(device.report_time, device.model)) {
        classes += ' status-error'
      }
      if (!device.monitoringMuted && this.isTimeCautionable(device.report_time, device.model)) {
        classes += ' status-caution'
      }
      return classes
    },
    refreshList (newFilter) {
      if (!newFilter && this.$route.query.filter) {
        newFilter = this.$route.query.filter
      }
      if (newFilter) {
        this.listFilter = newFilter
        localStorage.setItem('rdlTab', newFilter)
      } else {
        const v = localStorage.getItem('rdlTab')
        if (v) {
          this.listFilter = v
        }
      }
      this.refreshFunc()
    },
    retrieveMutedDevices () {
      this.isLoading = true
      FleetDataService.getMutedDevices()
        .then(response => {
          this.devices = response.data
        })
        .catch(e => {
          let message = 'Unable to Load Device List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    retrieveTempUninstalledDevices () {
      this.isLoading = true
      FleetDataService.getTempUninstalledDevices()
        .then(response => {
          const devices = []
          for (const device of response.data) {
            if (device.site !== 'orphaned-devices' &&
              device.site !== 'device-triage' &&
              device.site !== 'spare-devices'
            ) {
              device.notes = device.statusDetail
              devices.push(device)
            }
          }
          this.devices = devices
        })
        .catch(e => {
          let message = 'Unable to Load Device List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    retrieveProblematicDevices () {
      this.isLoading = true
      FleetDataService.getProblematicDevices()
        .then(response => {
          const devices = []
          for (const device of response.data) {
            if (!device.monitoringMuted &&
              device.site !== 'orphaned-devices' &&
              device.site !== 'device-triage' &&
              device.site !== 'spare-devices'
            ) {
              device.notes = device.statusDetail
              devices.push(device)
            }
          }
          this.devices = devices
        })
        .catch(e => {
          let message = 'Unable to Load Device List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    retrieveOverdueDevices () {
      this.isLoading = true
      FleetDataService.getOverdueDevices()
        .then(response => {
          this.devices = response.data
        })
        .catch(e => {
          let message = 'Unable to Load Device List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    retrieveRecentDevices () {
      this.isLoading = true
      FleetDataService.getRecentDevices()
        .then(response => {
          this.devices = response.data
        })
        .catch(e => {
          let message = 'Unable to Load Device List'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('devices-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('devices-list')
  },
  components: { DataTable, Modal }
}
</script>
