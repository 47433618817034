import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import auth from './app/auth'
import UserInfoApi from './app/user-info-api'
import { FontAwesomeIcon } from '@/plugins/font-awesome'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import '@/assets/styles/agrology.css'
import AdminDataService from './services/admin/AdminDataService'

const state = reactive({
  isSignedIn: false,
  email: '',
  userInfo: null,
  showInactiveNodes: false,
  crudMessage: '',
  creationName: '',
  currentSpace: '',
  impersonateRole: undefined
})

const fleetPathPrefixes = ['fleet', 'device', 'customer', 'site', 'manufacturer', 'network', 'gateway']

router.beforeResolve(async (to, from, next) => {
  if (!to || !to.path) {
    next()
    return
  }

  if (to.path.indexOf('id_token=') > 0 || to.path.indexOf('access_token=') > 0) {
    auth.auth.parseCognitoWebResponse(window.location.href.replace('#/', '#'))
    next({
      name: 'Home'
    })
    return
  }

  if (!auth.auth.isUserSignedIn()) {
    state.isSignedIn = false
    localStorage.setItem('preSigninLocation', window.location.hash.replace('#', ''))
    auth.auth.getSession()
  } else {
    state.isSignedIn = true
    if (!state.userInfo) {
      await UserInfoApi.getUserInfo().then(response => {
        if (response.email) {
          state.email = response.email
          state.userInfo = response
        }
      })
    }
    const loc = localStorage.getItem('preSigninLocation')
    if (loc) {
      localStorage.removeItem('preSigninLocation')
      next(loc)
      return
    }
  }

  if (auth.userPrivs === null) {
    await AdminDataService.getCurrentUserPrivileges()
      .then((response) => {
        const privs = response.data.privileges || []
        const roles = response.data.roles || []
        auth.setAuth(roles, privs)
        if (auth.isImpersonating) {
          state.impersonateRole = auth.userRoles[0]
        }
      })
  }

  if (to.name) {
    let s = to.name.toString()
    if (s.length > 1) {
      const x = s.indexOf('-')
      if (x > -1) {
        s = s.substring(0, x)
      }
      if (fleetPathPrefixes.includes(s)) {
        state.currentSpace = 'fleet'
      } else {
        state.currentSpace = ''
      }
    }
  }
  next()
})

const app = createApp(App)
app.config.globalProperties.$state = state
app
  .use(router)
  .component('fa', FontAwesomeIcon)
  .mount('#app')
