import http from '@/http-common'
import qs from 'qs'
import api from '@/services/fleet/api'
import auth from '@/app/auth'
import { reactive } from 'vue'
import { UplinkRelocationRequest, InstallationRecord } from './types'

class FleetDataService {
  manifest = reactive({
    data: {}
  })

  setManifest (data) {
    const out = {}
    if (data == null) {
      return out
    }
    const isAdmin = auth.isAdmin()
    const hasGlobal = isAdmin || auth.isGlobalViewer()
    const hasAlerts = auth.userHasPrivilegeSubject('alerts')
    const hasExclZone = auth.userHasPrivilegeSubject('notif-exclusion-zones')
    const hasManu = auth.userHasPrivilegeSubject('makes')
    const hasProducts = isAdmin
    const hasML = auth.isMLDeveloper()
    const hasSubs = auth.userHasPrivilegeSubject('subscriptions')
    const hasTmpls = auth.userHasPrivilegeSubject('templates')
    const hasThresh = auth.userHasPrivilegeSubject('thresholds')
    const fleetItems = ['Device', 'Gateway', 'Node', 'Site', 'Customer']

    for (const key in data) {
      const item = data[key]
      if (item == null) {
        continue
      }
      if (hasGlobal) {
        out[key] = item
        continue
      }
      if (item.type === 'Alert' && hasAlerts) {
        out[key] = item
      } else if (item.type === 'Device' && auth.userHasPrivilege('devices', 'view', item.customer)) {
          out[key] = item
      } else if (item.type === 'Node' && auth.userHasPrivilege('nodes', 'view', item.customer)) {
          out[key] = item
      } else if (item.type === 'Site' && auth.userHasPrivilege('sites', 'view', item.customer)) {
        out[key] = item
      } else if (item.type === 'Gateway' && auth.userHasPrivilege('gateways', 'view', item.customer)) {
        out[key] = item
      } else if (item.type === 'Customer' && auth.userHasPrivilege('cutomers', 'view', item.customer)) {
        out[key] = item
      } else if (fleetItems.includes(item.type) && item.customer && auth.userHasCustomer(item.customer)) {
        out[key] = item
      } else if (item.type === 'Firmware' && isAdmin) {
        out[key] = item
      } else if (item.route === 'exclusion-zones' && hasExclZone) {
        out[key] = item
      } else if (item.route === 'manufacturer-list' && hasManu) {
        out[key] = item
      } else if (item.route === 'modeling-home' && hasML) {
        out[key] = item
      } else if (item.route === 'products-list' && hasProducts) {
        out[key] = item
      } else if (item.route === 'admin-staff-access' && isAdmin) {
        out[key] = item
      } else if (item.route === 'subscription-list' && hasSubs) {
        out[key] = item
      } else if (item.type === 'Templates' && hasTmpls) {
        out[key] = item
      } else if (item.type === 'Thresholds' && hasThresh) {
        out[key] = item
      }
    }
    this.manifest.data = out
  }

  getManifest () {
    if (!this.manifest.data) {
      return {}
    }
    return this.manifest.data
  }

  getCustomerDisplayName (id: string): string {
    return this.getDisplayNameByElement('customer', id)
  }

  getSiteDisplayName (id: string): string {
    return this.getDisplayNameByElement('site', id)
  }

  getNodeDisplayName (id: string): string {
    return this.getDisplayNameByElement('node', id)
  }

  getDisplayNameByElement (elementType: string, elementId: string): string {
    const key = `${elementType}.${elementId}`
    if (this.manifest.data === null || !(key in this.manifest.data)) {
      return elementId
    }
    return this.manifest.data[key].title
  }

  getFlashingAppliances () {
    return http.get('/provisioning/flashing/appliances')
  }

  createFlashingAppliance (data: any) {
    return http.post('/provisioning/flashing/appliances', data)
  }

  updateFlashingAppliance (id: any, data: any) {
    return http.put(`/provisioning/flashing/appliances/${id}`, data)
  }

  rotateFlashingApplianceApiKey (id: any) {
    return http.post(`/provisioning/flashing/appliances/${id}`, {})
  }

  getSearchIndexURL () {
    return http.get('/provisioning/index')
  }

  getSearchIndex (url: string) {
    return http.get(url)
  }

  getSenetDevice (id: string, networkZone: string) {
    return http.get(`/provisioning/devices/senet/${id}/${networkZone}`)
  }

  getDeviceExportCSV (asTSV: boolean) {
    return http.get(`/provisioning/devices/export/all/${asTSV ? 'tsv' : 'csv'}`)
  }

  getGatewayExportCSV (asTSV: boolean) {
    return http.get(`/provisioning/gateways/export/all/${asTSV ? 'tsv' : 'csv'}`)
  }

  getSiteExportCSV (asTSV: boolean) {
    return http.get(`/provisioning/sites/export/all/${asTSV ? 'tsv' : 'csv'}`)
  }

  getNodeExportCSV (asTSV: boolean) {
    return http.get(`/provisioning/nodes/export/all/${asTSV ? 'tsv' : 'csv'}`)
  }

  getSenetDeviceCSV (id: string, networkZone: string, download = false, tsv = false) {
    let q = ''
    let sep = ''
    if (download || tsv) {
      q = '?'
    }
    if (download) {
      q += sep + 'download=senet_devices'
      sep = '&'
    }
    if (tsv) {
      q += sep + 'fmt=tsv'
      sep = '&'
    }
    return http.get(`/provisioning/devices/senet/${id}/${networkZone}/csv${q}`)
  }

  getAllSenetDevicesCSV (networkZone: string, download = false, tsv = false) {
    let q = ''
    let sep = ''
    if (download || tsv) {
      q = '?'
    }
    if (download) {
      q += sep + 'download=senet_devices'
      sep = '&'
    }
    if (tsv) {
      q += sep + 'fmt=tsv'
      sep = '&'
    }
    return http.get(`/provisioning/devices/senet/all/csv/${networkZone}${q}`)
  }

  activateSenetDevice (id: string, networkZone: string) {
    return http.get(`/provisioning/devices/senet/activate/${id}/${networkZone}`)
  }

  deactivateSenetDevice (id: string, networkZone: string) {
    return http.get(`/provisioning/devices/senet/deactivate/${id}/${networkZone}`)
  }

  registerSenetDevice (id: string, networkZone: string, params: string) {
    return http.post(`/provisioning/devices/senet/${id}/${networkZone}${params}`)
  }

  updateSenetDevice (id: string, networkZone: string) {
    return http.put(`/provisioning/devices/senet/${id}/${networkZone}`)
  }

  transferSenetDevice (id: string, oldZone: string, newZone: string) {
    return http.get(`/provisioning/devices/senet/transfer/${id}/${oldZone}/${newZone}`)
  }

  // Customers CRUD

  listCustomers () {
    return http.get('/provisioning/customers')
  }

  getCustomer (id: string) {
    return http.get(`/provisioning/customers/${id}`)
  }

  createCustomer (data: any) {
    return http.post('/provisioning/customers', qs.stringify(data))
  }

  updateCustomer (id: string, data: any) {
    return http.put(`/provisioning/customers/${id}`, qs.stringify(data))
  }

  // Devices Bulk Import

  bulkImportValidate (data: any) {
    return http.post('/provisioning/devices/import/bulk/verify', data)
  }

  bulkImportApply (data: any) {
    return http.post('/provisioning/devices/import/bulk/apply', data)
  }

  // Devices CRUD
  getDevice (id: string) {
    return http.get(`/provisioning/devices/${id}`)
  }

  createDevice (data: any) {
    return http.post('/provisioning/devices', qs.stringify(data))
  }

  updateDevice (id: any, data: any) {
    return http.put(`/provisioning/devices/${id}`, qs.stringify(data))
  }

  getDevicesBySite (siteName: string) {
    return http.get(`/provisioning/sites/${siteName}/devices`)
  }

  getDevicesByGateway (id: string) {
    return http.get(`/provisioning/gateways/${id}/devices`)
  }

  getRecentDevices () {
    return http.get('/provisioning/devices/recent')
  }

  getOverdueDevices () {
    return http.get('/provisioning/devices/overdue')
  }

  getMutedDevices () {
    return http.get('/provisioning/devices/muted')
  }

  getProblematicDevices () {
    return http.get('/provisioning/devices/problems')
  }

  getTempUninstalledDevices () {
    return http.get('/provisioning/devices/temp-uninstalled')
  }

  // Sites CRUD
  async getAllSites () {
    let data = {}
    await api.get('/provisioning/sites', 30, 'sitesList')
      .then(response => {
        data = response
      })
      if (data === undefined) {
        return null
      }
    return data
  }

  async getSite (id: string) {
    let data = {}
    await api.get(`/provisioning/sites/${id}`, 30, 'sitesLookup', id)
      .then(response => {
        data = response
      })
    if (data === undefined) {
      return null
    }
    return data
  }

  createSite (data: any) {
    api.deleteCacheEntry('sitesList')
    api.deleteCacheEntry('sitesByCustomerList')
    return api.post('/provisioning/sites', qs.stringify(data), 'sitesLookup')
  }

  updateSite (id: any, data: any) {
    api.deleteCacheEntry('sitesList')
    api.deleteCacheEntry('sitesByCustomerList')
    return api.put(`/provisioning/sites/${id}`, qs.stringify(data), 'sitesLookup')
  }

  getSitesByCustomer (customerName: string) {
    return http.get(`/provisioning/customers/${customerName}/sites`)
  }

  // Nodes CRUD
  async getSiteNodes (site: string) {
    let data = {}
    await api.get(`/provisioning/nodes/${site}`)
      .then(response => {
        data = response
      })
    if (data === undefined) {
      return null
    }
    return data
  }

  getSiteNode (site: string, id: string) {
    return api.get(`/provisioning/nodes/${site}/${id}`, 30, `site-nodes-${site}`, id)
  }

  updateSiteNode (site: string, id: string, data: any) {
    return api.put(`/provisioning/nodes/${site}/${id}`, qs.stringify(data), `site-nodes-${site}`)
  }

  createSiteNode (site: string, data: any) {
    return api.post(`/provisioning/nodes/${site}`, qs.stringify(data), `site-nodes-${site}`)
  }

  // Irrigation CRUD

  updateSiteIrrigation (id: string, data: any) {
    return api.put(`/provisioning/sites/${id}`, qs.stringify(data), 'sitesLookup')
  }

  // Network Servers CRUD
  getAllNetworkServers () {
    return http.get('/provisioning/networkServers')
  }

  getNetworkServer (id: string) {
    return http.get(`/provisioning/networkServers/${id}`)
  }

  createNetworkServer (data: any) {
    return http.post('/provisioning/networkServers', qs.stringify(data))
  }

  updateNetworkServer (id: string, data: any) {
    return http.put(`/provisioning/networkServers/${id}`, qs.stringify(data))
  }

  // Manufacturers CRUD
  getAllManufacturers () {
    return http.get('/provisioning/manufacturers')
  }

  getManufacturer (id: string) {
    return http.get(`/provisioning/manufacturers/${id}`)
  }

  createManufacturer (data: any) {
    return http.post('/provisioning/manufacturers', qs.stringify(data))
  }

  updateManufacturer (id: string, data: any) {
    return http.put(`/provisioning/manufacturers/${id}`, qs.stringify(data))
  }

  // Gateways CRUD
  getAllGateways () {
    return http.get('/provisioning/gateways')
  }

  getTempUninstalledGateways () {
    return http.get('/provisioning/gateways/temp-uninstalled')
  }

  getGatewaysBySite (siteName: string) {
    return http.get(`/provisioning/sites/${siteName}/gateways`)
  }

  getGateway (id: string) {
    return http.get(`/provisioning/gateways/${id}`)
  }

  createGateway (data: any) {
    return http.post('/provisioning/gateways', qs.stringify(data))
  }

  updateGateway (id: string, data: any) {
    return http.put(`/provisioning/gateways/${id}`, qs.stringify(data))
  }

  requestGatewayCredentials (id: string) {
    return http.get(`/provisioning/gateways/${id}/credentials/request`)
  }

  getGatewayCredentials (id: string, code: string) {
    return http.get(`/provisioning/gateways/${id}/credentials/confirm/${code}`)
  }

  // Flux Chambers & Vents
  getAllFluxChambers () {
    return http.get('/provisioning/flux-chambers')
  }

  createChamber (data: any) {
    return http.post(`/provisioning/flux-chambers`, data)
  }

  updateChamber (id: string, data: any) {
    return http.put(`/provisioning/flux-chambers/${id}`, data)
  }

  getAllChamberVents () {
    return http.get('/provisioning/chamber-vents')
  }

  createChamberVent (data: any) {
    return http.post(`/provisioning/chamber-vents`, data)
  }

  updateChamberVent (id: string, data: any) {
    return http.put(`/provisioning/chamber-vents/${id}`, data)
  }

  // Firmware CRUD
  listFirmwareVersionsByMakeModel (make: string, model: string) {
    return http.get(`/provisioning/firmware/${make}/${model}/versions`)
  }

  updateFirmwareVersion (make: string, model: string,
    version: string, data: any) {
    return http.put(`/provisioning/firmware/${make}/${model}/versions/${version}`,
      data)
  }

  createFirmwareVersion (make: string, model: string, data: any) {
    return http.post(`/provisioning/firmware/${make}/${model}/versions`,
      data)
  }

  // Products CRUD
  listAllProducts () {
    return http.get('/provisioning/products')
  }

  getProduct (name: string) {
    return http.get(`/provisioning/products/${name}`)
  }

  updateProduct (name: string, data: any) {
    return http.put(`/provisioning/products/${name}`, data)
  }

  createProduct (data: any) {
    return http.post('/provisioning/products', data)
  }

  // ChangeLog
  listChangeLogBy (elementType: string, elementId: string) {
    return http.get(`/provisioning/change-log/${elementType}/${elementId}`)
  }

  // NodeLayouts
  listNodeLayouts () {
    return http.get(`/provisioning/node-layouts`)
  }

  listNodeLayoutsByFunction (func: string) {
    return http.get(`/provisioning/node-layouts/function/${func}`)
  }

  updateNodeLayout (id: string, data: any) {
    return http.put(`/provisioning/node-layouts/${id}`, data)
  }

  createNodeLayout (data: any) {
    return http.post('/provisioning/node-layouts', data)
  }

  // Uplink Relocator
  createRelocationRequest(data: UplinkRelocationRequest) {
    return http.post('/provisioning/uplink/relocation', data)
  }

  // General Jobs
  getJobsByService(service: string) {
    return http.get(`/provisioning/jobs/service-name/${service}`)
  }

  // installations
  createInstallationRecord(data: InstallationRecord) {
    return http.post('/provisioning/installations', data)
  }

}

export default new FleetDataService()
