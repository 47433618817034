<template>
  <div>
    <ModalRunInfo ref="runInfoModal" :run="activeRun" :reloadFunc="loadRuns" />
    <ModalStartRun ref="startRunModal" :job="job" />
    <DataTable class="dt-modeling-runs mb-2"
      title="Recent Job Runs" :cols="cols" :rows="rows" :loading="isLoading"
      :actionFunc="showNewRunModal"
      actionIcon="play"
      actionLabel=" Start Ad-Hoc Run"
      noDataMessage="No Recent Runs for this Job"
      :initialSort="0"
    >
    </DataTable>
    <span class="subtle font-reduce-2 ms-1">Scheduled → Starting → Running → Completed / Failed</span>
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable.vue'
import BaseComponent from '@/components/BaseComponent.vue'
import ModalRunInfo from '@/components/modeling/ModalRunInfo.vue'
import ModalStartRun from '@/components/modeling/ModalStartRun.vue'
import ModelingDataService from '@/services/ModelingDataService'
import Reloadables from '@/services/reloadables'
import HelpContent from '@/services/HelpContent'

export default {
  extends: BaseComponent,
  components: { DataTable, ModalRunInfo, ModalStartRun },
  props: ['job', 'runID'],
  data () {
    return {
      activeRun: null,
      runs: [],
      cols: [
        { icon: 'clock', sort: 0 },
        { text: 'Type', sort: 1 },
        { text: 'Script Ver', sort: 2 },
        { text: 'Status', sort: 3 },
        { icon: 'chart-area' }
      ],
      isLoading: false
    }
  },
  name: 'modeling-job-runs-list',
  watch: {
    job: {
      handler () {
        if (this.job) {
          this.loadRuns()
        }
      }
    }
  },
  computed: {
    rows () {
      const out = []
      if (this.runs && this.runs.length > 0) {
        for (const i in this.runs) {
          out.push([
            { value: this.getSinceSecs(this.runs[i].startTime) + ' ago', click: this.showRunInfoModal, i: i, sortValue: (Date.now() / 1000) - this.runs[i].startTime },
            { value: this.runs[i].runType },
            { value: this.runs[i].scriptVersion },
            {
              value: this.runs[i].status,
              class: {
                notable: this.runs[i].status === 'running' || this.runs[i].status === 'starting' || this.runs[i].status === 'scheduled',
                subtle: this.runs[i].status === 'completed' || this.runs[i].status === 'canceled',
                'status-danger': this.runs[i].status === 'failed'
              }
            },
            { extLink: this.getLink(this.runs[i]), value: this.noLinkVal(this.runs[i]) }
          ])
        }
      }
      return out
    }
  },
  methods: {
    noLinkVal (run) {
      if (run.status !== 'completed' || (run.status === 'completed' && !run.chartURL)) {
        return '--'
      }
      return ''
    },
    getLink (run) {
      if (!run.chartURL) {
        return ''
      }
      return run.chartURL
    },
    showNewRunModal () {
      this.$refs.startRunModal.show()
    },
    showRunInfoModal (i) {
      this.activeRun = this.runs[i]
      this.$refs.runInfoModal.show()
    },
    loadRuns () {
      this.isLoading = true
      ModelingDataService.listJobRuns(this.job.name)
        .then(result => {
          this.runs = result.data
        })
        .catch(e => {
          let message = 'Failed to retrieve Job Runs list'
          if (e.response && e.response.data && e.response.data.message) {
            message += `: ${e.response.data.message}`
          }
          HelpContent.setFlashMessage(message, true)
        })
        .finally(() => {
          this.isLoading = false
          if (this.runID) {
            for (const i in this.runs) {
              if (this.runs[i].startTime && this.runs[i].startTime.toString() === this.runID) {
                this.showRunInfoModal(i)
              }
            }
          }
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('job-runs-list', this.loadRuns)
    this.loadRuns()
  },
  unmounted () {
    Reloadables.unregisterReloadable('job-runs-list')
  }
}
</script>
