<template>
  <div>
    <DataTable
      class="dt-gateways"
      :title="listTitle"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      :routerLink="canAddGateway ? 'gateway-add' : undefined"
      actionIcon="plus"
      itemType="GW"
      :initialSort="1"
    >
      <template v-slot:filter-pillbox>
        <div class="btn-group dt-pillbox me-2" role="group" aria-label="Gateway State Selector">
          <input type="radio" class="btn-check" value="all" name="listFilter"
            id="listFilter-all" v-model="listFilter" autocomplete="off" @click="refreshList('all')" />
          <label class="btn btn-outline-primary" for="listFilter-all" title="All Gateways">
            <fa icon="clock" class="pillbox-icon" :class="{'pillbox-icon-selected': listFilter === 'all'}" />
          </label>
          <input type="radio" class="btn-check" value="prod" name="listFilter"
            id="listFilter-prod" v-model="listFilter" autocomplete="off" @click="refreshList('prod')" />
          <label class="btn btn-outline-primary" for="listFilter-prod" title="Monitored Gateways">
            <fa icon="bell" class="pillbox-icon" :class="{'pillbox-icon-selected': listFilter === 'prod'}" />
          </label>
          <input type="radio" class="btn-check" value="nonprod" name="listFilter"
            id="listFilter-nonprod" v-model="listFilter" autocomplete="off" @click="refreshList('nonprod')" />
          <label class="btn btn-outline-primary" for="listFilter-nonprod" title="Unmonitored Gateways">
            <fa icon="bell-slash" class="pillbox-icon" :class="{'pillbox-icon-selected': listFilter === 'nonprod'}" />
          </label>
          <input type="radio" class="btn-check" value="tu" name="listFilter"
            id="listFilter-tu" v-model="listFilter" autocomplete="off" @click="refreshList('tu')" />
          <label class="btn btn-outline-primary" for="listFilter-tu" title="Temporarily Uninstalled Gateways">
            TU
          </label>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import BaseFleetComponent from '@/components/fleet/BaseFleetComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'

import FleetDataService from '@/services/fleet/FleetDataService'
import Reloadables from '@/services/reloadables'
import auth from '@/app/auth'

export default {
  name: 'gateways-list',
  extends: BaseFleetComponent,
  data () {
    return {
      listFilter: 'prod',
      cols: [
        { text: 'Gateway EUI', sort: 0 },
        { icon: 'clock', sort: 1 },
        { text: 'Inst', sort: 2 },
        { text: 'Site', sort: 3 },
        { icon: 'heart-pulse', sort: 4 },
        { text: 'GPS', sort: 5 },
        { text: 'Make', sort: 6 },
        { text: 'Model', sort: 7 },
        { icon: 'power-off', sort: 8 },
        { icon: 'globe', sort: 9 },
        { text: 'LoRa', sort: 10 },
        { text: 'Notes', sort: 11 }
      ],
      loading: false
    }
  },
  computed: {
    canAddGateway () {
      return auth.userHasPrivilege('gateways', 'create', '*')
    },
    rows () {
      const out = []
      if (this.filteredGatewayList && this.filteredGatewayList.length > 0) {
        for (const gw of this.filteredGatewayList) {
          const key = ('gateway.' + gw.id).toLowerCase()
          if (!(key in FleetDataService.manifest.data)) {
            continue
          }
          out.push([
            { value: gw.id, route: 'gateway-details', params: { id: gw.id }, class: this.gwLinkColor(gw, '') },
            { value: this.getSince(gw.report_time), class: this.lastReportClass(gw), sortValue: Date.now() - gw.report_time },
            { value: this.installationStatus(gw) },
            { value: this.siteDisplayName(gw.site), leftIcon: this.siteIcon(gw.site), class: { 'status-danger': !gw.site } },
            { value: this.isProd(!gw.monitoringMuted && gw.site), style: 'text-align: center' },
            { value: this.formatHasGPS(gw), leftIcon: this.formatHasGPSIcon(gw), class: { 'dt-centered': true, 'status-danger': !this.formatHasGPS(gw) } },
            { value: gw.manufacturer },
            { value: gw.model },
            { value: this.powerSourceText(gw.powerSource), sortValue: gw.powerSource, leftIcon: this.powerSourceIcon(gw.powerSource) },
            { value: this.connText(gw.networkAccess), sortValue: gw.networkAccess, leftIcon: this.connIcon(gw.networkAccess) },
            { value: gw.networkServer },
            { value: gw.notes }
          ])
        }
      }
      return out
    },
    filteredGatewayList () {
      if (this.listFilter === 'prod') {
        const out = []
        for (const i in this.activeGateways) {
          if (this.activeGateways[i].site && !this.activeGateways[i].monitoringMuted) {
            out.push(this.activeGateways[i])
          }
        }
        return out
      }
      if (this.listFilter === 'nonprod') {
        const out = []
        for (const i in this.activeGateways) {
          if (this.activeGateways[i].monitoringMuted || !this.activeGateways[i].site) {
            out.push(this.activeGateways[i])
          }
        }
        return out
      }
      if (this.listFilter === 'tu') {
        const out = []
        for (const i in this.activeGateways) {
          if (this.activeGateways[i].installationStatus === 'tempUninstalled') {
            out.push(this.activeGateways[i])
          }
        }
        return out
      }
      return this.activeGateways
    },
    listTitle () {
      if (this.listFilter === 'prod') {
        return 'Monitored Gateways'
      } else if (this.listFilter === 'nonprod') {
        return 'Unmonitored Gateways'
      } else if (this.listFilter === 'tu') {
        return 'Temp Uninstalled Gateways'
      }
      return 'All Gateways'
    }
  },
  methods: {
    siteDisplayName (input) {
      return FleetDataService.getSiteDisplayName(input)
    },
    siteIcon (site) {
      if (site) {
        return ''
      }
      return 'exclamation'
    },
    formatHasGPSIcon (elem) {
      if (elem && elem.lat && elem.long) {
        return ''
      }
      return 'exclamation'
    },
    formatHasGPS (elem) {
      if (elem && elem.lat && elem.long) {
        return '✓'
      }
      return ''
    },
    powerSourceIcon (powerSource) {
      if (!powerSource) {
        return ''
      }
      if (powerSource === 'wired') {
        return 'plug'
      }
      if (powerSource === 'solar') {
        return 'sun'
      }
      return ''
    },
    powerSourceText (powerSource) {
      if (!powerSource) {
        return '-'
      }
      if (powerSource === 'wired') {
        return ''
      }
      if (powerSource === 'solar') {
        return ''
      }
      return powerSource
    },
    connIcon (conn) {
      if (!conn) {
        return ''
      }
      if (conn === 'cellular') {
        return 'signal'
      }
      if (conn === 'wifi') {
        return 'wifi'
      }
      if (conn === 'ethernet') {
        return 'ethernet'
      }
      return ''
    },
    connText (conn) {
      if (!conn) {
        return '-'
      }
      if (conn === 'cellular') {
        return ''
      }
      if (conn === 'solar') {
        return ''
      }
      if (conn === 'ethernet') {
        return ''
      }
      return conn
    },
    isProd (b) {
      if (b) {
        return '✓'
      }
      return '-'
    },
    gwLinkColor (gw, baseClass) {
      let classes = baseClass
      if (gw.status === 'inactive') {
        classes += ' inactive'
      }
      return classes
    },
    lastReportClass (gw) {
      let classes = ''
      if (!gw || !gw.site) {
        return classes
      }
      if (!gw.monitoringMuted && this.isTimeNotable(gw.report_time)) {
        classes += ' notable'
      }
      if (!gw.monitoringMuted && this.isTimeAlertable(gw.report_time)) {
        classes += ' status-error'
      }
      if (!gw.monitoringMuted && this.isTimeCautionable(gw.report_time)) {
        classes += ' status-caution'
      }
      return classes
    },
    refreshList (newFilter) {
      this.loading = true
      if (!newFilter && this.$route.query.filter) {
        newFilter = this.$route.query.filter
      }
      if (newFilter) {
        this.listFilter = newFilter
        localStorage.setItem('gwlistTab', newFilter)
      } else {
        const v = localStorage.getItem('gwlistTab')
        if (v) {
          this.listFilter = v
        }
      }
      FleetDataService.getAllGateways()
        .then(response => {
          this.gateways = response.data
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    Reloadables.registerReloadable('gateways-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('gateways-list')
  },
  components: { DataTable }
}
</script>
