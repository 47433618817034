<template>
  <div>
    <Modal ref="addDeviceModal" :siteID="siteData.id" :customer="customerData" />
    <DataTable
      class="dt-info-pane-sm"
      :title="listTitle"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      :actionFunc="canAddDevice ? showAddDeviceModal : undefined"
      actionIcon="plus"
      itemType="Device"
      noDataMessage="No Devices"
      :initialSort="1"
      :expandable="true"
    >
    </DataTable>
  </div>
</template>

<script>
import BaseDeviceComponent from '@/components/fleet/BaseDeviceComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'
import Modal from '@/components/fleet/ModalAddUpdateDevice.vue'
import auth from '@/app/auth'

export default {
  name: 'devices-by-site-list',
  extends: BaseDeviceComponent,
  data () {
    return {
      listFilter: 'recent',
      cols: [
        { text: 'Device EUI', sort: 0 },
        { icon: 'clock', sort: 1 },
        { text: 'Inst', sort: 2 },
        { text: 'Node', sort: 3 },
        { text: 'Pos', sort: 4 },
        { text: 'Model', sort: 5 },
        { text: 'SNR', sort: 6, expandedOnly: true },
        { text: 'RSSI', sort: 7, expandedOnly: true },
        { text: 'Notes', sort: 8 }
      ],
      entityTypeName: this.entityType,
      loading: false
    }
  },
  props: ['entityType', 'siteData', 'nodes', 'deviceList', 'customerData'],
  computed: {
    canAddDevice () {
      return auth.userHasPrivilege('devices', 'create', this.siteData ? this.siteData.customer : 'XXX')
    },
    listTitle () {
      return 'Devices for this ' +
        this.siteClassification.charAt(0).toUpperCase() + this.siteClassification.slice(1)
    },
    siteClassification () {
      if (this.siteData && this.siteData.siteClassification) {
        return this.siteData.siteClassification
      }
      return this.entityTypeName
    },
    activeDeviceList () {
      if (!this.deviceList || this.deviceList.length === 0) {
        return []
      }
      if (this.$state.showInactiveNodes) {
        return this.deviceList
      }
      var active = []
      for (const device of this.deviceList) {
        if (device.status === 'inactive') {
          continue
        }
        active.push(device)
      }
      return active
    },
    rows () {
      const out = []
      if (this.activeDeviceList && this.activeDeviceList.length > 0) {
        for (const device of this.activeDeviceList) {
          out.push([
            {
              value: device.id,
              route: 'device-details',
              params: { id: device.id },
              class: { inactive: device.status === 'inactive' },
              isWarning: device.reportingStatus && device.reportingStatus === 'warning'
            },
            {
              value: this.getSince(device.report_time),
              class: {
                'status-error': !device.monitoringMuted && this.isTimeAlertable(device.report_time, device.model),
                'status-caution': !device.monitoringMuted && this.isTimeCautionable(device.report_time, device.model),
                notable: !device.monitoringMuted && this.isTimeNotable(device.report_time)
              },
              sortValue: Date.now() - device.report_time
            },
            { value: this.installationStatus(device) },
            { value: this.nodeDisplayName(device.node) },
            { value: this.shortPos(device.position) },
            { value: this.shortModel(device.model) },
            { value: device.snr, class: this.snrClass(device.snr) },
            { value: device.rssi, class: this.rssiClass(device.rssi) },
            { value: device.notes }
          ])
        }
      }
      return out
    },
    nodeLookup () {
      if (!this.nodes) {
        return null
      }
      const out = {}
      for (const i in this.nodes) {
        out[this.nodes[i].id] = this.nodes[i]
      }
      return out
    }
  },
  methods: {
    snrClass (input) {
      if (input > 0) {
        return 'status-ok'
      }
      if (input <= -5) {
        return 'status-warning'
      }
      return ''
    },
    rssiClass (input) {
      if (input < -125) {
        return 'status-danger'
      }
      if (input < -110) {
        return 'status-warning'
      }
      if (input >= -95) {
        return 'status-ok'
      }
      return ''
    },
    nodeDisplayName (input) {
      if (!this.nodeLookup || !(input in this.nodeLookup)) {
        return input
      }
      return this.nodeLookup[input].displayName
    },
    showAddDeviceModal () {
      this.$refs.addDeviceModal.show()
    },
  },
  components: { DataTable, Modal }
}
</script>
