export const install = 'install'
export const installed = 'installed'
export const uninstall = 'uninstall'
export const uninstalled = 'uninstalled'
export const tempUninstall = 'tempUninstall'
export const tempUninstalled = 'tempUninstalled'

const soilProbeModels = ['v1_ground', 'v2', 'trs12', 'trs21'] as string[]

export interface validationResponse {
    isValid: boolean
    details?: string[]
}

const PrettyUninstalled = 'Uninstalled'
const PrettyTempUninstalled = 'Uninstalled (Temporary)'
const PrettyInstalled = 'Installed'

export function statuses(): any {
  return {
    uninstalled: PrettyUninstalled,
    installed: PrettyInstalled,
    tempUninstalled: PrettyTempUninstalled
  }
}

export function validateGatewayForInstall(gateway: any, customer: string): validationResponse {
  const out = {} as validationResponse
  if (!gateway) {
    out.details = ['invalid gateway']
    return out
  }
  if ('installationStatus' in gateway && gateway.installationStatus === installed) {
    out.details = ['gateway is already installed']
    return out
  }
  out.details = [] as string[]
  if (customer === 'agrology') {
    out.details = [`Gateway must be in a Site oustide of the 'Agrology' Customer`]
  }
  if (!('status' in gateway) || gateway.status !== 'active') {
    out.details.push('Gateway Status is not set to Active')
  }
  if (!gateway.manufacturer) {
    out.details.push('Gateway Manufacturer is not set')
  }
  if (!gateway.model) {
    out.details.push('Gateway Model is not set')
  }
  if (!gateway.powerSource) {
    out.details.push('Gateway Power Source is not set')
  }
  if (!gateway.networkAccess) {
    out.details.push('Gateway Internet Source is not set')
  }
  if (!gateway.networkServer) {
    out.details.push('Gateway LoRa Server is not set')
  }
  if (!('lat' in gateway) || !('long' in gateway)) {
    out.details.push('Gateway GPS Location is not set')
  }
  out.isValid = out.details.length === 0
  return out
}

export function validateDeviceForInstall(device: any, customer: string): validationResponse {
  const out = {} as validationResponse
  if (!device) {
    out.details = ['invalid device']
    return out
  }
  if ('installationStatus' in device && device.installationStatus === installed) {
    out.details = ['device is already installed']
    return out
  }
  out.details = [] as string[]
  if (customer === 'agrology') {
    out.details = [`Device must be in a Site oustide of the 'Agrology' Customer`]
  }
  if (!('status' in device) || device.status !== 'active') {
    out.details.push('Device Status is not set to Active')
  }
  if (!device.manufacturer) {
    out.details.push('Device Manufacturer is not set')
  }
  if (!device.model) {
    out.details.push('Device Model is not set')
  }
  if (!device.node) {
    out.details.push('Device Node is not set')
  }
  if (!device.position) {
    out.details.push('Device Position is not set')
  }
  if (soilProbeModels.includes(device.model)) {
    if (!device.probeDepth) {
      out.details.push('Device Soil Probe Depth is not set')
    } else {
      const num = toNumber(device.probeDepth)
      if (num === undefined || num < 1) {
        out.details.push('Device Soil Probe Depth is not set')
      }
    }
  }
  if (device.manufacturer === 'agrology' && !device.swVersionTarget) {
    out.details.push('Device Target Firmware Version is not set')
  }
  out.isValid = out.details.length === 0
  return out
}

export const toNumber = (value: any): number | undefined => {
  // If the value is already a number and not NaN, return it
  if (typeof value === 'number' && !isNaN(value)) {
    return value
  }

  // If the value is a string, try to convert it to a number
  if (typeof value === 'string') {
    const parsedValue = parseFloat(value)
    return isNaN(parsedValue) ? undefined : parsedValue
  }

  // Return undefined for anything else
  return undefined
}
