<template>
  <div class="d-flex">
    <label :class="labelClasses">{{ label }}</label>
    <div class="form-right-input" style="margin-top:4px;height:28px;margin-left: 2px;">
      <span :class="valueClass">{{ value }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['label', 'value', 'labelClass', 'valueClass'],
  computed: {
    labelClasses () {
      let out = 'me-2 pe-1 form-left-header'
      if (this.labelClass) {
        out += ` ${this.labelClass}`
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      return out
    }
  }
}
</script>
