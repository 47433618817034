<template>
  <div :class="wrapperClasses">
    <label :class="labelClasses" :for="id">
      {{ label }}
      <slot name="labelIcon" />
    </label>
    <div class="d-flex form-right-input align-items-center">
      <input
        type="text"
        :id="id"
        :value="modelValue"
        :required="required"
        :name="id"
        :style="inputStyle"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :readonly="readonly"
        :disabled="disabled || readonly"
        :class="inputClasses"
        @keypress="checkInput"
        @input="input"
      />
      <div v-if="okFunc" @click="okFunc" class="input-row-action input-row-ok">
        &#x2713;
      </div>
      <div v-if="cancelFunc" @click="cancelFunc" class="input-row-action input-row-cancel">
        &#x2715;
      </div>
      <span class="ms-2 subtle" v-if="suffix">{{ suffix }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['label', 'id', 'modelValue', 'labelClass', 'inputStyle', 'inputClass',
    'maxlength', 'placeholder', 'required', 'readonly', 'disabled', 'dataType',
    'suffix', 'okFunc', 'cancelFunc', 'valueChangedFunc', 'isError', 'wrapperClass'],
  emits: ['update:modelValue'],
  data () {
    return {
      content: this.value
    }
  },
  methods: {
    checkInput (e) {
      if (!e.key) {
        return
      }
      if (e.keyCode === 13 && this.okFunc) {
        e.preventDefault()
        this.okFunc()
        return
      }
      if (e.metaKey || e.ctrlKey) {
        return
      }
      if (this.dataTypeCharacters) {
        let v = e.key
        if (this.dataType === 'slug' || this.dataType === 'email') {
          v = v.toLowerCase()
        } else if (this.dataType === 'hex') {
          v = v.toUpperCase()
        }
        if (!this.dataTypeCharacters.includes(v)) {
          e.preventDefault()
        }
      }
    },
    input (e) {
      this.$emit('update:modelValue', e.target.value)
      if (this.valueChangedFunc) {
        this.valueChangedFunc(e)
      }
    }
  },
  computed: {
    wrapperClasses () {
      const base = 'd-flex input-row text-input-row align-items-center'
      if (this.wrapperClass) {
        return `${base} ${this.wrapperClass}`
      }
      return base
    },
    dataTypeCharacters () {
      if (!this.dataType) {
        return ''
      }
      if (this.dataType === 'slug') {
        return 'abcdefghijklmnopqrstuvwxyz0123456789-'
      }
      if (this.dataType === 'description') {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789 -\'.()/@#$%&*_+'
      }
      if (this.dataType === 'word') {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-\'.'
      }
      if (this.dataType === 'words') {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-\'. '
      }
      if (this.dataType === 'version') {
        return 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789._-'
      }
      if (this.dataType === 'phone') {
        return '0123456789-'
      }
      if (this.dataType === 'integer') {
        return '0123456789'
      }
      if (this.dataType === 'decimal') {
        return '0123456789.'
      }
      if (this.dataType === 'domain') {
        return 'abcdefghijklmnopqrstuvwxyz0123456789-.'
      }
      if (this.dataType === 'hexupper') {
        return '0123456789ABCDEF'
      }
      if (this.dataType === 'hexlower') {
        return '0123456789abcdef'
      }
      if (this.dataType === 'hex') {
        return '0123456789abcdefABCDEF'
      }
      if (this.dataType === 'email') {
        return 'abcdefghijklmnopqrstuvwxyz0123456789-._@'
      }
      if (this.dataType === 'semver') {
        return '0123456789.'
      }
      if (this.dataType === 'uuid') {
        return '0123456789abcdef-'
      }
      return ''
    },
    inputClasses () {
      let out = 'form-input text-input'
      if (this.isError) {
        out += ' text-input-error'
      }
      if (this.inputClass) {
        if (typeof this.inputClass === 'object') {
          for (const k in this.inputClass) {
            if (this.inputClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.inputClass}`
        }
      }
      return out
    },
    labelClasses () {
      let out = 'input-row-header'
      if (this.required) {
        out += ' label-required'
      }
      if (this.labelClass) {
        if (typeof this.labelClass === 'object') {
          for (const k in this.labelClass) {
            if (this.labelClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.labelClass}`
        }
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      return out
    }
  }
}
</script>
